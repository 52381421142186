import { connect } from 'react-redux';
import { onsiteSearch, type IOnsiteSearch } from '@/features/gmt/gmtActions';
import { getCookie } from '@utils/cookies';
import { getProducts } from '../stores/productSearchSlice';
import { selectProductSearchEntities, selectProductSearchLoading, selectSearchResultCount } from '../stores/selectors';
import { SearchBox } from '../Components/SearchBox';

const mapStateToProps = (state: any, ownProps?: any ) => {
  const initialShouldDisplayResults = Object.keys(selectProductSearchEntities(state)).length > 0 ?? false;

  const loadingState = selectProductSearchLoading(state);
  const isSearchFullfilled = loadingState === 'fulfilled' ?? false;
  const isSearchPending = loadingState === 'pending' ?? false;
  const searchResultCount = selectSearchResultCount(state);

  const props = {
    ...ownProps,
    showAddToCartButton: ownProps?.showAddToCartButton ?? true,
    initialShouldDisplayResults,
    isSearchPending,
    isSearchFullfilled,
    searchResultCount
  };

  return {
    ...props,
    ...state
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    logGtmSearchBoxEvent: ({ searchTerm, searchResultCount }: IOnsiteSearch) => {
      const onsiteSearchAction = onsiteSearch({ searchTerm, searchResultCount });
      dispatch(onsiteSearchAction);
    },
    onSearchQueryChange: (query: string, page?: number) => {
      const webPrefix = `${document.location.pathname}${document.location.search}`;
      const sector = getCookie('cl_sector');

      const urlParams = new URLSearchParams();
      urlParams.set('s', query);
      urlParams.set('ct', '3');
      urlParams.set('wp', webPrefix);
      urlParams.set('sector', sector);
      page && urlParams.set('page', page.toString());

      const apiUrl = `products/search?${urlParams.toString()}`;

      getProducts(dispatch)({ apiUrl });
    }
  };
};

export const ConnectedSearchBox = connect(mapStateToProps, mapDispatchToProps)(SearchBox);
