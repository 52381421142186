/**
  * A Higher Order Component that makes other components reusable outside Sitecore's context
  *
  * It provides Sitecore's 'data' props on the baselevel component props
  * and wraps the component in correct div's for SXA
  *
  * Based on: https://react-typescript-cheatsheet.netlify.app/docs/hoc/full_example/
  */

/**
 * Usage:

  export const MyInputComponent = ({ placeHolder = 'defaultValue', onChange }) => {
    return <input placeholder={placeHolder} onChange={onChange} />;
  };

  // Outside Sitecore
  <MyInputComponent placeHolder="Type here" onChange={e => setInputState(e.target.value)} />

  // Within Sitecore (props.data ... )
  const ScMyInputComponent = withSCProps(MyInputComponent)

*/


import React from 'react';
import objstr from 'obj-str';

interface ScProps<T> {
  children?: any;
  className?: string;
  data?: T;
  isEdit?: boolean;
  renderingCssClass?: string;
  styles?: string;
}


export function withScProps(WrappedComponent: React.ComponentType) {
  const WithSCProps = (props: ScProps<any>) => {

    const { data, ...ownProps } = props;

    const nextProps = {
      ...ownProps,
      ...( data ? {...data } : {})
    };


    const cssClassNames = objstr({
      [props.className]: !!props.className,
      [props.renderingCssClass]: !!props.renderingCssClass,
      [props.styles]: !!props.styles,
      component: true
    });

    return (
      <div
        className={cssClassNames}>
        <div className="component-content">
          <WrappedComponent {...nextProps} />
        </div>
      </div>
    );

  };
  const displayName = WrappedComponent.displayName || WrappedComponent.name || 'Component';
  WithSCProps.displayName = `WithScProps(${displayName})`;

  return WithSCProps;
}
