import React from 'react';
import { Provider } from 'react-redux';
import store from '@state/store';
import ConnectedSortOrder from '../Connectors/ConnectedSortOrder';
import type { ISortOrder } from '../Components/SortOrder/SortOrder';

export const ProvideSortOrder = (props: ISortOrder) => {
  return (
    <Provider store={store}>
      <ConnectedSortOrder { ...props }/>
    </Provider>
  );
};
