import { connect } from 'react-redux';
import { CategoryFacets } from '@components-react/ProductOverview/Components/CategoryFacets/CategoryFacets';
import { getProducts, toggleFacetsOpen } from '../stores/productSearchSlice';
import { selectFacets, selectFacetsOpen, selectSearchResultCount } from '../stores/selectors';
import type { IFacetItem } from '@components-react/ProductOverview/Components/CategoryFacets/CategoryFacets';

export const mapStateToProps = (state: any, ownProps?: any) => {
  const facets = selectFacets(state);
  const isOpen = selectFacetsOpen(state);
  const searchResultCount = selectSearchResultCount(state);

  return {
    ...ownProps,
    facets: facets?.length > 0 ? facets : (ownProps.facets ?? []),
    isOpen: isOpen ?? false,
    productCount: searchResultCount ?? ownProps.productCount,
    idPrefix: 'searchFacet'
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    onFacetChange: (facetItem: IFacetItem) => {
      return getProducts(dispatch)({
        apiUrl: facetItem.urlSet.api,
        webUrl: facetItem.urlSet.web
      });
    },
    toggleFacetsOpen: () => {
      dispatch(toggleFacetsOpen());
    }
  };
};

const ConnectedCategoryFacets = connect(mapStateToProps, mapDispatchToProps)(CategoryFacets);
export default ConnectedCategoryFacets;
