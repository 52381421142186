import React, { useState } from 'react';
// The npm version uses 'meta.import...' to generate an url to the confirm audio file.
// React.NET can't handle that, causing a crash on render
import { QrScanner } from './react-qr-scanner';
import styles from './BarcodeScanner.module.scss';

interface IBarcodeScanner {
  onScanResult: (...args: any) => any
}

export const BarcodeScanner = ({ onScanResult }: IBarcodeScanner) => {

  const [error, setError] = useState<string>();

  return (
    <div className={styles.barcodeScanner} data-testid="barcodeScanner">
      {error && <span>{error}</span>}
      <QrScanner
        onResult={onScanResult}
        onError={(e) => setError(e.message)}
        tracker={true}
        hideCount={false}
      />
    </div>
  );
};
