import * as React from 'react';
import { Provider } from 'react-redux';
import store from '@state/store';
import ConnectedPagination from '../Connectors/ConnectedPagination';

export const ProvidePagination = (props: any) => {
  // Sitecore provides the props as { data: { pagination: { // the actual props } }

  const { pagination, ...ownProps } = props;

  const componentProps = {
    ...ownProps,
    ...( pagination ? {...pagination } : {})
  };

  return (
    <Provider store={store}>
      <ConnectedPagination { ...componentProps }/>
    </Provider>
  );
};
