import * as React from 'react';
import { ISrcset } from './ISrcset';

export const Srcset = ({ image }: { image: ISrcset }) => {
  if (!image?.items || image.items.length === 0) {
    return null;
  }

  return (
    <picture>
      {image.items.map((item, sequence) => (
        <source key={sequence} media={item.media} srcSet={item.src} />
      ))}

      <img src={image.defaultSrc} alt={image.imageCaption} className="img-fluid" />
    </picture>
  );
};
