import * as React from 'react';
import { useRef } from 'react';
import objstr from 'obj-str';
import { Swiper, SwiperSlide, Navigation } from '@lib/swiper';
import { VARIANT_TYPE_COLOR } from '@utils/productConstants';
import { VariantOption } from '@utils/mapProductsData';
import styles from './VariantOptions.module.scss';

interface IOption {
  item: VariantOption;
  optionCode: string;
  onOptionCodeSelect: (optionCode: string, item: VariantOption) => void;
  selected?: boolean;
  allowDisable: boolean;
}

interface IVariantOptions {
  items: VariantOption[];
  type: string;
  variant: VariantOption;
  optionCode: string;
  onOptionCodeSelect: (optionCode: string, item: VariantOption) => void;
  allowDisable: boolean;
}


const ColorOption = ({ item, optionCode, onOptionCodeSelect, selected = false, allowDisable = true }: IOption) => {
  const classNames = objstr({
    [styles.ColorOption]: true,
    [styles.ColorOptionSelected]: item?.selected || selected,
    [styles.LabelOptionDisabled]: allowDisable && item.disabled,
    transition: true
  });

  return (
    <span
      className={classNames}
      role="button"
      onClick={() => onOptionCodeSelect(optionCode, item)}
    >
      <img
        src={item.image}
        alt={item.value.value}
        title={item.value.value}
        className={styles.ColorOptionImage}
      />
    </span>
  );
};

const LabelOption = ({ item, optionCode, onOptionCodeSelect, selected = false, allowDisable = true }: IOption) => {
  const classNames = objstr({
    [styles.LabelOption]: true,
    [styles.LabelOptionSelected]: item?.selected || selected,
    [styles.LabelOptionDisabled]: allowDisable && item.disabled,
    transition: true
  });

  return (
    <span
      className={classNames}
      onClick={() => onOptionCodeSelect(optionCode, item)}
      role="button"
    >
      {item.value.value}
    </span>
  );
};

export const VariantOptions = (
  { items = [], type, variant, optionCode, onOptionCodeSelect, allowDisable = true }: IVariantOptions
) => {
  const isColorOption = type === VARIANT_TYPE_COLOR;
  const swiperRef = useRef(null);

  if (!variant) {
    console.log('Variant options called when variant is null');
  }

  return (
    <div className={`${styles.VariantOptions} option-${type}-${optionCode}`}>
      <div className={styles.SwiperWrapper}>
        <Swiper
          onSwiper={(swiper) => {
            swiperRef.current = swiper;
          }}
          nested={true}
          modules={[Navigation]}
          navigation={{
            lockClass: 'd-none',
            disabledClass: 'disabled',
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev'
          }}
          slidesPerView="auto"
          slidesOffsetBefore={36}
          slidesOffsetAfter={36}
          spaceBetween={16}
          observer
        >
          <button className={'swiper-button-prev'} onClick={() => swiperRef.current.slidePrev()}></button>
          <button className={'swiper-button-next'} onClick={() => swiperRef.current.slideNext()}></button>

          {items.map((item: VariantOption, idx: number) => {
            const selected = variant && item.value.code === variant.value.code;

            return (
              <SwiperSlide key={idx}>
                {isColorOption && (
                  <ColorOption
                    item={item}
                    optionCode={optionCode}
                    onOptionCodeSelect={onOptionCodeSelect}
                    selected={selected}
                    allowDisable={allowDisable}
                  />
                )}
                {!isColorOption && (
                  <LabelOption
                    item={item}
                    optionCode={optionCode}
                    onOptionCodeSelect={onOptionCodeSelect}
                    selected={selected}
                    allowDisable={allowDisable}
                  />
                )}
              </SwiperSlide>
            );
          })}
        </Swiper>
      </div>
    </div>);
};
