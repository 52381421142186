import React from 'react';
import { useTranslation } from '@hooks/useTranslation';
import { Modal } from '@components-react/Modal/Modal';
import styles from './AddToOrderListModal.module.scss';

export interface ISuccessMessageModalProps {
  phrases: IPhrases;
  dismissModal: any;
  thumbnail: any;
  code: string;
  title: string;
  displayTax: boolean;
  price: string;
  unitDescription: string;
}

export const SuccessMessageModal = (props: ISuccessMessageModalProps) => {
  const { dismissModal, thumbnail, code, title, price, unitDescription, displayTax, phrases } = props;
  const { t } = useTranslation(phrases);

  const modalButtons = [{
    type: 'primary',
    label: t('AddToOrderList_Finish'),
    onClick: dismissModal
  }];

  const HeaderComponent = () => {
    return (
      <div className={`${styles.modalHeader}`}>
        <h1 className="h1 mb-3">{ t('AddToOrderList_Success_Message') }</h1>
        <div className="d-flex">
          {thumbnail && <div className="mr-3"><img src={thumbnail} className={styles.thumbnail} /></div>}
          <div className="flex-column">
            <h2 className="h3">{title}</h2>
            <div className="text-sans-serif text-gray-500">{t('AddToOrderList_ProductCode')} {code}</div>
            <div className="d-flex flex-row align-items-center">
              <span className="h3">{price}</span> <span className={styles.priceLabel}>{unitDescription}</span>
            </div>
            <p className="text-sans-serif text-xs text-muted">
              {(displayTax ? t('AddToOrderList_IncTax') : t('AddToOrderList_ExTax'))}
            </p>
          </div>
        </div>
      </div>
    );
  };


  return (
    <Modal
      HeaderComponent={HeaderComponent}
      title={t('AddToOrderList_Success_Title')}
      buttons={modalButtons}
      onModalClose={dismissModal}
      hasFooterSeparator
    >
    </Modal>
  );
};
