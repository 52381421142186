import React, { useEffect, useRef } from 'react';
import { Swiper, SwiperSlide, Pagination, Lazy } from '@lib/swiper';
import { Media as MediaData } from '@sctypes/product/Product';
import imageService from '@services/image-service';
import styles from './Media.module.scss';
import type{ Swiper as SwiperRef } from 'swiper';

export const Media = ({ media = [], alt = '' }: { media: MediaData[], alt: string }) => {
  const swiperRef = useRef<SwiperRef>();

  useEffect(() => {
    if(!swiperRef.current) {
      return;
    }
    swiperRef.current?.slideTo(0);
    swiperRef.current?.update();
    swiperRef.current?.lazy.load();

  }, [media, swiperRef]);

  if (media.length === 0) {
    return null;
  }

  return (
    <div className={styles.mediaSwiper}>
      <Swiper
        onSwiper={(swiper) => {
          swiperRef.current = swiper;
        }}
        nested
        modules={[Pagination, Lazy]}
        pagination={{
          type: 'bullets',
          clickable: true
        }}
        lazy={true}
        preloadImages={false}
        centeredSlides={true}
        // The next line somehow causes an endless loop when ProductTile is rendered in SearchResults on DNN pages
        // spaceBetween={32}
      >
        {media.filter(Boolean).map((mediaItem: MediaData, idx: number) => {
          const imgHeight = 400;
          const imgWidth = 400;
          const mediaUrl = imageService.getUrl(mediaItem.src, imgWidth, imgHeight, true);
          const isFirst = idx === 0;

          return (
            <SwiperSlide key={idx}>
              {isFirst && (<img src={mediaUrl} alt={alt} loading="eager" width={imgWidth} height={imgHeight} className={styles.image}/>)}
              {!isFirst && (<img data-src={mediaUrl} alt={alt} className={`swiper-lazy ${styles.image}`}/>)}
            </SwiperSlide>
          );
        })
        }
      </Swiper>
    </div>
  );
};
