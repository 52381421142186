import * as React from 'react';
import { useDispatch } from 'react-redux';
import { toggleFacetsOpen } from '../../stores/productsListingSlice';
import './OpenFacetsButton.scss';

export const OpenFacetsButton = (props: OpenFacetsButtonData) => {
  const dispatch = useDispatch();

  return (
    <button
      className={'btn btn-outline-primary btn-lg d-lg-none'}
      onClick={() => dispatch(toggleFacetsOpen())}
      type="button"
      data-testid="OpenFacetsButton"
    >
      {props.phrases?.open_facets_button ?? ''}
    </button>
  );
};

export interface OpenFacetsButtonData {
  phrases: any
}
