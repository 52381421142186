import { connect } from 'react-redux';
import { Cannot, selectAcl } from '@state/userSlice';
import { AppSecurityScope } from '@sctypes/AppSecurityScope';
import { selectCart, getCart } from '../cartSlice';
import { ShoppingCartButton } from './ShoppingCartButton';

const mapStateToProps = (state: any, ownProps?: any) => {
  const cartData = selectCart(state);
  const acl = selectAcl(state);

  const disabled = Cannot(acl, AppSecurityScope.CARTS_CREATE);

  return {
    ...ownProps,
    ...cartData,
    disabled
  };
};


const mapDispatchToProps = (dispatch: any) => {
  return {
    getCart: () => getCart(dispatch)()
  };
};

export const ConnectShoppingCartButton = connect(mapStateToProps, mapDispatchToProps)(ShoppingCartButton);
