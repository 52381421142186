import * as React from 'react';
import { Srcset } from '@components-react/Srcset/Srcset';
import { ScReact } from '../ScReact';
import { ScProps } from '../ScProps';
import styles from './CallToAction.module.scss';
import { CallToActionData } from './CallToActionData';

export const CallToAction = (props: ScProps<CallToActionData>) => {
  const { data: {
    title,
    subtitle,
    image,
    imageOrientation,
    link,
    content
  }, isEdit } = props;
  const orientation = imageOrientation === 'left' ? 'order-first' : 'order-last';
  const shouldDisplay = isEdit || subtitle;

  return <ScReact {...props} className={styles.CallToAction}>
    <div className="row">
      <div className={`col-12 col-md-6 d-grid place-items-center mt-3 mt-xl-0 ${orientation}`}>
        <Srcset image={image} />
      </div>

      <div className="col-12 col-md-6 align-self-center">
        <h2 className={`${styles.title} h1`} dangerouslySetInnerHTML={{ __html: title }} />

        {shouldDisplay && <h3 className={styles.subtitle} dangerouslySetInnerHTML={{ __html: subtitle }} />}

        <p className={styles.content} dangerouslySetInnerHTML={{ __html: content }} />
        <a href={link.href} type="button" className={styles.button}>{link.text}</a>
      </div>
    </div>
  </ScReact>;
};
