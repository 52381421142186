import { connect } from 'react-redux';
import { getProducts, toggleFacetsOpen } from '../stores/productsListingSlice';
import { selectFacets, selectFacetsOpen, selectTotal } from '../stores/selectors';
import { CategoryFacets } from '../Components/CategoryFacets/CategoryFacets';
import type { ICategoryFacets, IFacetItem } from '../Components/CategoryFacets/CategoryFacets';

export const mapStateToProps = (state: any, ownProps: ICategoryFacets) => {
  const facets = selectFacets(state);
  const isOpen = selectFacetsOpen(state);
  const total = selectTotal(state);

  return {
    ...ownProps,
    facets: facets?.length > 0 ? facets : (ownProps.facets ?? []),
    isOpen: isOpen ?? false,
    productCount: total ?? ownProps.productCount
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    onFacetChange: (facetItem: IFacetItem) => {
      return getProducts(dispatch)({
        apiUrl: `${facetItem.urlSet.api}?content=1`,
        webUrl: facetItem.urlSet.web
      });
    },
    toggleFacetsOpen: () => {
      dispatch(toggleFacetsOpen());
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CategoryFacets);
