import * as React from 'react';
import { CodeValue } from '@sctypes/product/Product';
import styles from './Labels.module.scss';

export const Labels = ({ labels = [] }: { labels: CodeValue[] }) => {
  return (
    <div className={styles.Labels}>
      {labels.map(label => {
        return (
          <span className={`${styles.Label} ${styles[`_${label.code}`]} mb-2`} key={label.code}>
            {label.value}
          </span>);
      }
      )}
    </div>
  );
};
