import React from 'react';
import { Provider, useSelector, useDispatch } from 'react-redux';
import { selectProduct } from '@components/ProductDetail/state/productDetailSlice';
import store from '@state/store';
import { ScReact } from '@components-react/ScReact';
import { ScProps } from '@components-react/ScProps';
import { AddToOrderListModal } from '../AddToOrderListModal/AddToOrderListModal';
import { displayOrderListsModal } from '../state/orderListsSlice';

interface IAddToOrderLists {
  iconClass: string;
  caption: string;
  phrases: IPhrases;
}


export const AddToOrderListsButton = (props: ScProps<IAddToOrderLists>) => {
  const { iconClass, caption } = props.data;
  const dispatch = useDispatch();
  const product = useSelector(state => selectProduct(state));

  const showOrderListsModal = () => {
    displayOrderListsModal(dispatch)({
      title: product.title,
      code: product.code,
      media: product.variant?.media,
      unitOfMeasureCode: product?.unitOfMeasureCode,
      variant: product?.variant
    });
  };

  return (
    <ScReact {...props}>
      <a
        role="button"
        onClick={showOrderListsModal}
      >
        <span className={`fa fa-fw ${iconClass} text-theme-2`}></span>{caption}
      </a>
    </ScReact>
  );
};

export const AddToOrderLists = (props: ScProps<IAddToOrderLists>) => (
  <Provider store={store}>
    <AddToOrderListsButton {...props} />
    <AddToOrderListModal {...props} />
  </Provider>
);
