import { connect } from 'react-redux';
import { selectSortOrder } from '../stores/selectors';
import { getProducts } from '../stores/productsListingSlice';
import { SortOrder } from '../Components/SortOrder/SortOrder';
import type { ISortOrder, ISortOrderItem } from '../Components/SortOrder/SortOrder';

export const mapStateToProps = (state: any, ownProps: ISortOrder) => {
  const items = selectSortOrder(state);

  return {
    ...ownProps,
    items: items?.length > 0 ? items : (ownProps.items ?? [])
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    updateSortOrder: (item: ISortOrderItem) => getProducts(dispatch)({
      apiUrl: item.urlSet.api,
      webUrl: item.urlSet.web
    })
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SortOrder);
