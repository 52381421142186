import React from 'react';
import { Srcset } from '@components-react/Srcset/Srcset';
import { ScReact } from '../ScReact';
import { ScProps } from '../ScProps';
import styles from './CallToActionSmall.module.scss';
import { CallToActionSmallData } from './CallToActionSmallData';

export const CallToActionSmall = (props: ScProps<CallToActionSmallData>) => {
  const { data: {
    title,
    subtitle,
    image,
    link
  }, isEdit } = props;
  const shouldDisplay = isEdit || subtitle;

  return <ScReact {...props} className={styles.CallToActionSmall}>
    <div className="row">
      <div className="col-12 col-md-6">
        <h2 className={`${styles.title} h1`} dangerouslySetInnerHTML={{ __html: title }} />

        {shouldDisplay && <p className={styles.subtitle} dangerouslySetInnerHTML={{ __html: subtitle }} />}
        <a href={link.href} className="btn btn-outline-primary btn-xl">{link.text}</a>
      </div>

      <div className="col-12 col-md-6">
        <Srcset image={image} />
      </div>
    </div>
  </ScReact>;
};
