import * as React from 'react';
import { ScReact } from '../ScReact';
import { ScProps } from '../ScProps';
import styles from './ExampleTypeScript.module.scss';

export const ExampleTypeScript = (props: ScProps<any>) => {
  const { data } = props;
  return <ScReact {...props} className={styles.ExampleTypeScript}>
    <h1 className={styles.title}>{data.title}</h1>
    <p className={styles.text}>{data.text}</p>
  </ScReact>;
};
