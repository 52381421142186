import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Modal } from '@components-react/Modal/Modal';
import { cartSlice } from '../cartSlice';
import styles from './AddToCart.module.scss';

// TODO: Replace this.
function getPhrases(): IPhrases {
  let phrases = {
    Phrases: {}
  };
  try {
    phrases = JSON.parse(document.querySelector('[data-component=add-to-cart]').textContent);
  } catch (e) {
    // Do nothing
  }

  return phrases?.Phrases;
}

export const AddToCartLayer = () => {
  const dispatch = useDispatch();
  const [isShown, setIsShown] = useState(false);
  const [variant, setVariant] = useState({ title: '', code: '', media: [] });
  const lastAction = useSelector((state:any) => state.lastAction);
  const isAddToCartAction = lastAction.type === cartSlice.actions.addToCart.type;
  const phrases = getPhrases();

  if (!isShown && isAddToCartAction) {
    setVariant(lastAction.payload.items[0].currentVariant);
    setIsShown(true);
  }

  const { title = '', code = '', media = [] } = variant;
  const { thumbnail = null } = media?.find(mediaItem => !!mediaItem.thumbnail) || {};

  const dismissModal = () => {
    dispatch({
      type: 'modal/dismissModal'
    });

    setIsShown(false);
    setVariant({ title: '', code: '', media: [] });
  };

  const gotoToBasketPage = () => {
    const vNext = window.carelFeatureToggles && window.carelFeatureToggles['shoppingcart']?.value === '1';

    window.location.href = vNext ? '/winkelwagen?return=c' : '/webshop/winkelwagen';
    setIsShown(false);
    setVariant({ title: '', code: '', media: [] });
  };

  const buttons = [{
    type: 'outline-primary',
    label: phrases.AddToCart_ContinueShopping,
    onClick: dismissModal
  },{
    type: 'primary',
    label: phrases.AddToCart_GotoBasketPage,
    onClick: gotoToBasketPage
  }];

  if (isShown) {
    return (
      <Modal
        title={phrases.AddToCart_Title}
        buttons={buttons}
        onModalClose={dismissModal}
        hasFooterSeparator
      >
        <div className="d-flex">
          {thumbnail && <div className="mr-3"><img src={thumbnail} className={styles.thumbnail} /></div>}
          <div className="flex-direction-row">
            <h2 className="h3">{title}</h2>
            <span className="text-sans-serif text-gray-500">{`${phrases.AddToCart_ItemNo}${code}`}</span>
          </div>
        </div>
      </Modal>
    );
  }

  return null;
};
