import * as React from 'react';
import { useEffect } from 'react';
import { Provider, useSelector } from 'react-redux';
import { selectProduct } from '@components/ProductDetail/state/productDetailSlice';
import './QuotationRequest.scss';
import store from '@state/store';
import { ScReact } from '@components-react/ScReact';
import { ScProps } from '@components-react/ScProps';

const QuotationRequestComponent = (props: ScProps<any>) => {
  const { data } = props;

  useEffect(() => {
    const script = document.createElement('script');
    script.src = '/dist/scripts/ProductDetailsQuotationRequest.js';
    script.async = true;
    document.body.appendChild(script);
  }, []);

  const product = useSelector(state => selectProduct(state));

  if (!product) {
    return null;
  }

  let unitOfMeasure = product?.variant.unitsOfMeasure[0].name;
  if (product?.unitOfMeasureCode) {
    const unit = product?.variant.unitsOfMeasure.find((uom: any) => uom.code === product.unitOfMeasureCode);
    if (unit) {
      unitOfMeasure = unit.name;
    }
  }

  return (
    <ScReact {...props}>
      <a href="#" data-toggle="modal" data-target="#mdlRequestQuote">
        <span className={`fa fa-fw ${data.iconClass} text-theme-2`}></span>{data.caption}
      </a>

      <div id="mdlRequestQuote" className="modal fade" role="dialog" aria-hidden="true">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">{data.caption}</h5>
              <a href="" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></a>
            </div>
            <div id="_form_137_" className="modal-body">
              <input type="hidden" name="u" defaultValue="137" />
              <input type="hidden" name="f" defaultValue="137" />
              <input type="hidden" name="s" />
              <input type="hidden" name="c" defaultValue="0" />
              <input type="hidden" name="m" defaultValue="0" />
              <input type="hidden" name="act" defaultValue="sub" />
              <input type="hidden" name="v" defaultValue="2" />
              <input type="hidden" name="field[30]" />

              <div className="_form-content">
                <div className="form-row">
                  <div className="form-group col">
                    <label className="sr-only">Product</label>
                    <input
                      className="form-control"
                      name="field[31]"
                      placeholder="Product" type="text"
                      required
                      readOnly
                      value={product?.variant.title}
                    />
                  </div>
                </div>
                <div className="form-row">
                  <div className="form-group col">
                    <label className="sr-only">Inhoud</label>
                    <input
                      className="form-control"
                      name="field[32]"
                      placeholder="Inhoud"
                      type="text"
                      required
                      readOnly
                      value={unitOfMeasure}
                    />
                  </div>
                  <div className="form-group col">
                    <label className="sr-only">Aantal</label>
                    <input className="form-control" name="field[33]" placeholder="Aantal" type="text" required defaultValue="1" />
                  </div>
                </div>
                <div className="form-row">
                  <div className="form-group col">
                    <label className="sr-only">Voornaam</label>
                    <input className="form-control" name="firstname" placeholder="Voornaam" type="text" data-name="firstname" required />
                  </div>
                  <div className="form-group col">
                    <label className="sr-only">Achternaam</label>
                    <input className="form-control" name="lastname" placeholder="Achternaam" type="text" data-name="lastname" required />
                  </div>
                </div>
                <div className="form-row">
                  <div className="form-group col">
                    <label className="sr-only">Telefoonnummer</label>
                    <input className="form-control" name="phone" placeholder="Telefoonnummer" type="text" data-name="phone" required />
                  </div>
                  <div className="form-group col">
                    <label className="sr-only">Emailadres</label>
                    <input className="form-control" name="email" placeholder="Emailadres" type="text" data-name="email" required />
                  </div>
                </div>
                <div className="form-row">
                  <div className="form-group col">
                    <label className="sr-only">Organisatie</label>
                    <input
                      className="form-control"
                      name="field[41]"
                      placeholder="Organisatie"
                      type="text"
                      data-name="organization"
                      required
                    />
                  </div>
                  <div className="form-group col">
                    <label className="sr-only">&nbsp;</label>
                    <button className="btn btn-primary btn-block" id="_form_137_submit" type="button">Aanvragen</button>
                  </div>
                </div>
              </div>
              <div className="_form-thank-you alert alert-success" style={{ display: 'none' }}>&nbsp;</div>
            </div>
          </div>
        </div>
      </div>
    </ScReact>
  );
};

export const QuotationRequest = (props: ScProps<any>) => (
  <Provider store={store}>
    <QuotationRequestComponent {...props} />
  </Provider>
);
