import React, { useEffect } from 'react';
import clsx from 'clsx';
import styles from './ShoppingCartButton.module.scss';

interface IShoppingCartButton {
  totalPieces?: number;
  onClick?: () => void;
  size?: 'sm' | 'md' | 'lg';
  variant?: 'primary' | 'outline-primary' | 'theme';
  features: { [key: string]: string };
  disabled?: boolean;
  getCart: () => void
}
export const ShoppingCartButton = ({
  totalPieces = 0,
  variant = 'theme',
  size = 'lg',
  features,
  disabled = false,
  getCart
}: IShoppingCartButton) => {
  const classNames = clsx({
    [styles.disabled]: !!disabled,
    [styles.shoppingCart]: true,
    [styles[`btn-${variant}`]]: true,
    [styles[`btn-${size}`]]: true
  });

  useEffect(() => {
    getCart();
  }, [getCart]);

  const vNext = features['shoppingcart'] === '1';

  const anchorProps = {
    ...(!disabled ? { href: vNext ? '/winkelwagen?return=c' : '/webshop/winkelwagen' } : {}),
    className: `${styles.btnShoppingcart} ${disabled ? 'disabled' : ''} btn btn-primary shadow-sm transition`
  };

  return (
    <div className={classNames}>
      <a {...anchorProps}>
        {/* <FontAwesomeIcon icon={faCartShopping} size="xl" className={styles.icon} /> */}
        <svg viewBox="0 0 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg">
          <path // eslint-disable-next-line
            d="M 0.50899614,0.5 V 3.6 H 4.0437821 l 4.061902,12.121 -2.325516,3.1 h -0.03101 c -0.713156,0.992 -0.744163,2.325 -0.248053,3.286 0.496111,0.961 1.550345,1.643 2.759614,1.643 H 28.415197 v -3.1 H 8.2607191 c 0,0 -0.03101,-0.031 0,-0.062 L 10.586235,17.55 h 14.573239 c 0.620137,0 1.271282,-0.31 1.426316,-0.93 L 31.360852,5.77 C 31.825955,4.685 31.050783,3.6 29.965541,3.6 H 7.2995051 L 6.6173541,1.554 6.2762781,0.5 Z M 8.2607191,25.3 c -1.705379,0 -3.100689,1.395 -3.100689,3.1 0,1.705 1.39531,3.1 3.100689,3.1 1.705379,0 3.1006879,-1.395 3.1006879,-3.1 0,-1.705 -1.3953089,-3.1 -3.1006879,-3.1 z m 17.0537889,0 c -1.705379,0 -3.100689,1.395 -3.100689,3.1 0,1.705 1.39531,3.1 3.100689,3.1 1.705379,0 3.100689,-1.395 3.100689,-3.1 0,-1.705 -1.39531,-3.1 -3.100689,-3.1 z"
            fill="currentColor"
            stroke="currentColor"
          />
        </svg>
        <span className={`${styles.badge} position-absolute badge rounded-pill`} data-basket-count={totalPieces}>
          {totalPieces}
        </span>
      </a>
    </div>
  );
};
