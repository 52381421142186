import { connect } from 'react-redux';
import { SortOrder } from '@components-react/ProductOverview/Components/SortOrder/SortOrder';
import { selectSortOrder } from '../stores/selectors';
import { getProducts } from '../stores/productSearchSlice';
import type { ISortOrderItem } from '@components-react/ProductOverview/Components/SortOrder/SortOrder';

export const mapStateToProps = (state: any, ownProps?: any) => {
  const items = selectSortOrder(state);

  return {
    ...ownProps,
    items: items?.length > 0 ? items : []
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    updateSortOrder: (item: ISortOrderItem) => getProducts(dispatch)({
      apiUrl: item.urlSet.api,
      webUrl: item.urlSet.web
    })
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SortOrder);
