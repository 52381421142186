import React from 'react';
import { UrlSet } from '@sctypes/product/UrlSet';
import styles from './Pagination.module.scss';

export interface IPaginationItem {
  code: string;
  label: string;
  urlSet: UrlSet;
  selected: boolean;
  isSeparator: boolean;
}

export interface IPagination {
  // relative: Relative;
  items: IPaginationItem[];
  relative: {
    prev?: IPaginationItem;
    next?: IPaginationItem;
  }
  first?: IPaginationItem;
  last?: IPaginationItem;
  onPaginationChange?: (arg0: IPaginationItem) => void
}

export const Pagination = ({
  items,
  relative,
  // first,
  // last,
  onPaginationChange
}: IPagination) => {

  const hasPrevPage = !!relative?.prev?.urlSet?.web;
  const hasNextPage = !!relative?.next?.urlSet?.web;

  const handlePageChange = async (e: React.MouseEvent<HTMLAnchorElement>, item: IPaginationItem) => {
    e.preventDefault();
    onPaginationChange(item);

    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  return (
    <div className={styles.PaginationContainer}>
      <ul className={styles.Pagination}>
        {hasPrevPage && (
          <li className="d-none d-sm-block">
            <a
              href={relative.prev.urlSet?.web}
              className={`${styles.PageLink} ${styles.PageLinkPrev} btn btn-square`}
              rel="prev"
              onClick={(e) => handlePageChange(e, relative.prev)}
            >{relative.prev.label}</a>
          </li>
        )}

        {items?.length > 0 && (items.map((item: IPaginationItem, idx: number) => {
          return (
            <li key={idx}>
              <a href={item.urlSet?.web}
                className={`${styles.PageLink} ${item?.selected ? styles.PageLinkSelected : ''}`}
                onClick={(e: React.MouseEvent<HTMLAnchorElement>) => handlePageChange(e, item)}
              >{item.label}</a>
            </li>
          );
        }))}

        {hasNextPage && (
          <li className="d-none d-sm-block">
            <a
              href={relative.next.urlSet?.web}
              className={`${styles.PageLink} ${styles.PageLinkNext} btn btn-square`}
              rel="next"
              onClick={(e) => handlePageChange(e, relative.next)}
            >{relative.next.label}</a>
          </li>
        )}
      </ul>
    </div>

  );
};
