import React, { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { FullPageScrollPane } from '@components-react/FullPageScrollPane/FullPageScrollPane';
import { Portal } from '@components-react/Portal/Portal';
import { useOverflowHidden } from '@hooks/useOverflowHidden';
import { useDebounce } from '@hooks/useDebounce';
import { useLazyEffect } from '@hooks/useLazyEffect';
import { BarcodeScanner } from './BarcodeScanner';

import styles from './SearchBox.module.scss';
import { SearchModal } from './SearchModal';

type RestProps = {
  [x: string]: { phrases?: IPhrases; }
};

export interface ISearchBox {
  inputPlaceholder?: string;
  title?: string;
  onSearchQueryChange: (...args: any) => any;
  initialShouldDisplayResults?: boolean;
  isSearchPending?: boolean;
  isSearchFullfilled?: boolean;
  logGtmSearchBoxEvent: (...args: any) => any;
  searchResultCount: number;
  showAddToCartButton?: boolean;
}

export const SearchBox = ({
  inputPlaceholder = '',
  onSearchQueryChange,
  initialShouldDisplayResults = false,
  title,
  isSearchPending,
  isSearchFullfilled,
  logGtmSearchBoxEvent,
  searchResultCount,
  showAddToCartButton = true,
  ...restProps
}: ISearchBox & RestProps) => {
  // Play nice with SSR
  const isBrowser = typeof window !== 'undefined';

  // Patch in 'phrases' as returned by Sitecore
  // These are defined in `React.CreateElement()` calls generated by React.NET
  const phrasesFromSitecore = {
    categoryFacets: restProps?.dataFacets?.phrases,
    filterBadges: restProps?.dataFilters?.phrases,
    productCount: restProps?.dataProductCount?.phrases,
    productTileList: restProps?.dataProducts?.phrases,
    ...(restProps.phrases && restProps.phrases)
  };

  const [query, setQuery] = useState('');
  const [shouldDisplayResults, setShouldDisplaceResults] = useState(
    initialShouldDisplayResults
  );
  const [showBarcodeScanner, setShowBarcodeScanner] = useState(false);
  useOverflowHidden(showBarcodeScanner);

  const updateUrlParams = (searchQuery: string, page?: number) => {
    const hash = `#sqr:(q[${searchQuery}]${page ? `p[${page}]` : ''})`;
    document.location.hash = hash;
  };

  useEffect(() => {
    // parse #sqr:(q[<query>]p[<pageNumber>])
    const queryParam =
      decodeURIComponent(window.location.hash).match(/sqr:\(q\[(.+?)\](?:p\[(.+?)\])?\)/) ??
      [];

    const [/* unnused */ ,query, pageNumer = 1 ] = queryParam;

    if (query) {
      setQuery(query);
      onSearchQueryChange(query, pageNumer);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  useEffect(() => {
    setShouldDisplaceResults(initialShouldDisplayResults || isSearchFullfilled);

    return () => setShouldDisplaceResults(false);
  }, [initialShouldDisplayResults, isSearchFullfilled]);


  useLazyEffect(
    () => {
      if (query && isSearchFullfilled) {
        logGtmSearchBoxEvent({
          searchTerm: query,
          searchResultCount
        });
      }
    },
    [query, isSearchFullfilled],
    1500
  );

  const debouncedQueryChange = useDebounce(() => {
    onSearchQueryChange(query);
  }, 400);

  const handleSearchInputChange = async function (e: any) {
    const searchQuery = (e.target as HTMLInputElement).value;
    setQuery(searchQuery);
    updateUrlParams(searchQuery);
    debouncedQueryChange(searchQuery);
  };

  const handleSearchButtonClick = async function () {
    if (query) {
      onSearchQueryChange(query);
    }
  };

  const handleSearchClose = () => {
    setShouldDisplaceResults(false);
    history.replaceState(null, null, ' ');

    /**
     * TODO: Remove this when DNN is no longer used
     * DNN Hack, the "winkewagen" page does not use redux store to display products
     */
    if (window.location.pathname.indexOf('/webshop/winkelwagen') === 0) {
      window.location.reload();
    }
  };

  const handleBarcodeResult = (barcode: string) => {
    setShowBarcodeScanner(false);
    setQuery(barcode);
    onSearchQueryChange(barcode);
  };

  return (
    <>
      <div
        className={`${styles.SearchOverlay} ${
          shouldDisplayResults ? styles.SearchOverlayOpen : ''
        }`}
      ></div>
      <label className={`row ${styles.SearchBoxLabel}`}>
        <div className="col mr-auto">
          <input
            value={query}
            onChange={handleSearchInputChange}
            type="search"
            className={`${styles.SearchBoxInput}`}
            placeholder={inputPlaceholder}
            data-testid="SearchBox"
          />

          <button
            type="button"
            className={`${styles.SearchButton}`}
            onClick={handleSearchButtonClick}
          >
            <span className="sr-only">Search</span>
            {!isSearchPending && (
              <span className={styles.SearchIcon}>
                <svg
                  width="32"
                  height="32"
                  viewBox="0 0 32 32"
                  version="1.1"
                  id="svg4"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    // eslint-disable-next-line max-len
                    d="m 21.576923,13.769229 q 0,-3.224158 -2.291762,-5.5159297 -2.291771,-2.2917627 -5.515933,-2.2917627 -3.224158,0 -5.515926,2.2917627 -2.2917686,2.2917717 -2.2917686,5.5159297 0,3.224159 2.2917686,5.515932 2.291768,2.291765 5.515926,2.291765 3.224162,0 5.515933,-2.291765 2.291762,-2.291773 2.291762,-5.515932 z m 8.923084,14.500006 q 0,0.90625 -0.662259,1.568512 -0.662261,0.662259 -1.568511,0.662259 -0.941105,0 -1.56851,-0.662259 L 20.72296,23.877409 q -3.119596,2.161056 -6.953732,2.161056 -2.492189,0 -4.7665269,-0.967252 Q 6.7283606,24.103972 5.0814256,22.457034 3.4344893,20.810098 2.4672409,18.535762 1.4999925,16.261421 1.4999925,13.769229 q 0,-2.49219 0.9672484,-4.7665241 Q 3.4344893,6.7283606 5.0814256,5.0814228 6.7283606,3.4344847 9.0027011,2.4672439 11.277039,1.4999935 13.769228,1.4999935 q 2.492193,0 4.766529,0.9672504 2.274342,0.9672408 3.921276,2.6141789 1.646936,1.6469378 2.614186,3.9212821 0.967248,2.2743341 0.967248,4.7665241 0,3.834134 -2.16106,6.953733 l 5.977767,5.977763 q 0.644833,0.644834 0.644833,1.56851 z"
                    fill="currentColor"
                    stroke="currentColor"
                  />
                </svg>
              </span>
            )}
            {isSearchPending && <span className="fa fa-refresh fa-spin"></span>}
          </button>
        </div>
        <button
          type="button"
          className={`col-auto ml-auto ${styles.BarcodeButton} d-md-none`}
          onClick={() => setShowBarcodeScanner(true)}
        >
          <svg
            width="32"
            height="28"
            viewBox="0 0 32 28"
            version="1.1"
            id="svg3"
            xmlns="http://www.w3.org/2000/svg"
          >
            <defs id="defs7" />
            <path
              // eslint-disable-next-line max-len
              d="m 15.999999,11.403845 q 1.921354,0 3.285677,1.371093 1.364323,1.371095 1.364323,3.301984 0,1.93089 -1.364323,3.301983 Q 17.921353,20.75 15.999999,20.75 q -1.921354,0 -3.285677,-1.371095 -1.364323,-1.371093 -1.364323,-3.301983 0,-1.930889 1.364323,-3.301984 1.364323,-1.371093 3.285677,-1.371093 z M 27.366666,4.6538452 q 1.711458,0 2.922395,1.2169473 Q 31.5,7.0877395 31.5,8.8076915 V 23.346153 q 0,1.719952 -1.210939,2.936899 -1.210937,1.216947 -2.922395,1.216947 H 4.633333 q -1.7114578,0 -2.9223955,-1.216946 Q 0.49999978,25.066106 0.49999978,23.346154 V 8.8076915 q 0,-1.719952 1.21093772,-2.936899 Q 2.9218752,4.6538452 4.633333,4.6538452 H 8.2500001 L 9.0734373,2.4471144 Q 9.3802081,1.6520422 10.195573,1.0760206 11.010938,0.49999895 11.866665,0.49999895 h 8.266668 q 0.855729,0 1.671093,0.57602165 0.815365,0.5760216 1.122136,1.3710938 L 23.75,4.6538452 Z M 15.999999,23.346153 q 2.98698,0 5.110157,-2.133714 2.123177,-2.133714 2.123177,-5.135517 0,-3.001803 -2.123177,-5.135517 -2.123177,-2.1337135 -5.110157,-2.1337135 -2.986979,0 -5.110155,2.1337125 -2.1231769,2.133714 -2.1231769,5.135518 0,3.001803 2.1231769,5.135518 2.123176,2.133714 5.110155,2.133713 z"
              id="path793"
              fill="currentColor"
              stroke="currentColor"
            />
          </svg>

          <span className="sr-only">Scan code</span>
        </button>
      </label>

      {isBrowser &&
        createPortal(
          <Portal>
            <FullPageScrollPane
              PageHeader={
                <div className="container-fluid">
                  <div className="row">
                    <div className="col">
                      {/* TODO: Translations */}
                      <h1 className="h1">Scan QR- of barcode</h1>
                    </div>
                    <div className="col-auto ml-auto">
                      <button
                        type="button"
                        className="btn btn-link pr-0"
                        onClick={() => setShowBarcodeScanner(false)}
                      >
                        <span className="fa fa-times text-lg text-theme mr-1"></span>
                        {/* TODO: Translations */}
                        <span className="text-sans-serif text-gray-900 text-nowrap">
                          {/* @ts-expect-error: translations not typed */}
                          {phrasesFromSitecore['common:button.close']}
                        </span>
                      </button>
                    </div>
                  </div>
                </div>
              }
              isOpen={showBarcodeScanner}
            >
              {showBarcodeScanner && (
                <BarcodeScanner onScanResult={handleBarcodeResult} />
              )}
            </FullPageScrollPane>
          </Portal>,
          document.body
        )}

      {isBrowser &&
        createPortal(
          shouldDisplayResults && (
            <SearchModal
              title={title}
              onCloseSearch={handleSearchClose}
              phrasesFromSitecore={phrasesFromSitecore}
              showAddToCartButton={showAddToCartButton}
            />
          ),
          document.body
        )}
    </>
  );
};

