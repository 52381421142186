import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toggleFacetsOpen } from '../stores/productSearchSlice';
import { selectFacetsOpen, selectOptions, selectSearchResultCount } from '../stores/selectors';
import { ConnectedProductTileList } from '../Connectors/ConnectProductTileList';
import FilterBadges from '../Connectors/ConnectedFilterBadges';
import SortOrder from '../Connectors/ConnectedSortOrder';
import Pagination from '../Connectors/ConnectedPagination';
import CategoryFacets from '../Connectors/ConnectedCategoryFacets';
import { ProductCount } from './ProductCount';
import styles from './SearchModal.module.scss';


export interface ISearch {
  showAddToCartButton?: boolean;
  title: string;
  onCloseSearch: () => void;
  phrasesFromSitecore: IPhrases;
}


// eslint-disable-next-line max-len
export const SearchModal = ({
  showAddToCartButton = true,
  title,
  onCloseSearch,
  phrasesFromSitecore
}: ISearch) => {
  const dispatch = useDispatch();
  const isFacetsOpen = useSelector((state) => selectFacetsOpen(state));
  const searchResultCount = useSelector((state) => selectSearchResultCount(state)) ?? 0;
  const hasSearchResults = searchResultCount > 0;
  const { searchTerm = '' } = useSelector((state) => selectOptions(state));

  return (
    <div className={styles.ModalWrapper} data-testid="SearchModal">
      <div className={styles.SearchModal}>
        <div className="container">
          <div className="row">
            <div
              className={`${styles.Facets} ${
                !hasSearchResults ? styles.FacetsNoResults : ''
              } col-auto`}
            >
              <CategoryFacets
                phrases={phrasesFromSitecore.categoryFacets}
                isOpen={isFacetsOpen}
              />
            </div>

            <div className={`${styles.Results} col pt-3 pl-3`}>
              <div className="row">
                <div className="col-8 col-md-auto mr-auto">
                  <span className="h1 d-inline-block">{title}</span>
                  <ProductCount
                    total={searchResultCount}
                    phrases={phrasesFromSitecore}
                  />
                </div>
                <div className="col-auto ml-auto">
                  <button
                    type="button"
                    className="btn btn-link close"
                    onClick={onCloseSearch}
                    data-testid="SearchModal:closeSearchButton"
                  >
                    <span className="fa fa-times text-lg text-gray-900"></span>
                    <span className="sr-only">
                      {phrasesFromSitecore['searchmodal:facets.close']}
                    </span>
                  </button>
                </div>
              </div>
              <div className="row mt-3">
                <div className="col-12">
                  <FilterBadges phrases={phrasesFromSitecore.filterBadges} />
                </div>
              </div>
              <div className="row align-items-center mt-3">
                <div className="col-auto col-md-4">
                  {hasSearchResults && <SortOrder />}
                </div>
                <div className="col-auto ml-auto d-lg-none">
                  <button
                    className={'btn btn-outline-primary btn-lg'}
                    onClick={() => dispatch(toggleFacetsOpen())}
                    type="button"
                    data-testid="openFacetsButton"
                  >
                    <span className="fa fa-regular fa-filter d-xsplus-none"></span>
                    <span className="d-none d-xsplus-inline">
                      {phrasesFromSitecore['searchmodal:facets.open']}
                    </span>
                  </button>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  {hasSearchResults && (
                    <ConnectedProductTileList
                      showAddToCartButton={showAddToCartButton}
                      phrases={phrasesFromSitecore.productTileList}
                      fallbackImage={{
                        src: '/-/jssmedia/Project/Tenants/CarelLurvink/shared/Logo/no-image.png',
                        alt: ''
                      }}
                    />
                  )}

                  {!hasSearchResults && (
                    <>
                      <h2 className="h2 mb-2">
                        {phrasesFromSitecore['searchmodal:title.no-results']}
                      </h2>
                      {/* eslint-disable-next-line max-len */}
                      <p
                        className="text-muted"
                        dangerouslySetInnerHTML={{
                          __html: phrasesFromSitecore[
                            'searchmodal:message.no-results'
                          ]?.replace('{{searchterm}}', searchTerm)
                        }}
                      />
                      <a href="/webshop" className="btn btn-theme btn-xl">
                        {phrasesFromSitecore['searchmodal:button.all-products']}
                      </a>
                    </>
                  )}
                </div>
                <div className="col-12 pb-3">
                  <Pagination />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};




