import * as React from 'react';
import { Provider } from 'react-redux';
import store from '@state/store';
import { ScProps } from '@components-react/ScProps';
import { ProductVariantSelector } from './ProductVariantSelector';
import { ProductVariantSelectorProps } from './ProductVariantSelectorProps';

export const ProvideProductVariantSelector = (props: ScProps<ProductVariantSelectorProps>) => (
  <Provider store={store}>
    <ProductVariantSelector {...props} />
  </Provider>
);
