import noAttributeImage from '@components-react/ProductTile/assets/no-attribute-image.svg';
import type { CodeValue, Product, Variant } from '@sctypes/product/Product';
import type { AttributeList } from '@sctypes/product/AttributeList';
import type { Attribute } from '@sctypes/product/Attribute';

export interface VariantOption {
  typeCode: string;
  codeValue: CodeValue;
  value: CodeValue;
  disabled: boolean;
  image: string;
  selected?: boolean;
}

function mapVariantOptions(options: AttributeList): VariantOption[] {
  const typeCode = options.type.code;
  return options.items.map((item: Attribute) => {
    return {
      typeCode: typeCode,
      codeValue: item?.code || { code: '', value: '' },
      value: item?.value || { code: '', value: '' },
      disabled: false,
      image: (item.images?.length > 0 ? item.images[0].src : noAttributeImage)
    };
  });
}

export interface ProductData extends Product {
  availableOptionCodes1: VariantOption[];
  availableOptionCodes2: VariantOption[];
  variant: Variant;
  optionCode1: string;
  optionCode2: string;
  unitOfMeasureCode: string;
}

export function mapProductData(product: Product, chosenVariant?: Variant): ProductData {
  if (!product) {
    return null;
  }

  const { variants, variantOptions } = product;

  let variant = chosenVariant;
  const options1: VariantOption[] = variantOptions[0]?.items ? mapVariantOptions(variantOptions[0]) : [];
  const options2: VariantOption[] = variantOptions[1]?.items ? mapVariantOptions(variantOptions[1]) : [];

  let optionCode1 = '';
  let optionCode2 = '';
  if (chosenVariant) {
    optionCode1 = chosenVariant.optionCode1;
    optionCode2 = chosenVariant.optionCode2;
  } else {
    variant = variants.find(variant => variant.code === product.defaultVariantCode);

    if (variant) {
      optionCode1 = variant.optionCode1;
      optionCode2 = variant.optionCode2;
    }
  }

  if (optionCode1 !== '') {
    for (let index = 0; index < options2.length; index++) {
      const optionCode = options2[index].value.code;
      const hasVariant = variants.find(variant => variant.optionCode1 === optionCode1 && variant.optionCode2 === optionCode);

      if (!hasVariant) {
        options2[index].disabled = true;
      }
    }
  }

  return {
    ...product,
    availableOptionCodes1: options1,
    availableOptionCodes2: options2,
    variant,
    optionCode1,
    optionCode2,
    unitOfMeasureCode: variant?.unitsOfMeasure[0].code
  };
}
