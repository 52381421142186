import * as React from 'react';
import { Provider } from 'react-redux';
import store from '@state/store';
import ConnectedCategoryFacets from '../Connectors/ConnectedCategoryFacets';
import type { ICategoryFacets } from '../Components/CategoryFacets/CategoryFacets';

export const ProvideCategoryFacets = (props: ICategoryFacets) => {
  return (
    <Provider store={store}>
      <ConnectedCategoryFacets { ...props }/>
    </Provider>
  );
};
