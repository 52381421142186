import { Prices } from '@sctypes/product/Prices';
import { useApi } from '../../hooks/use-api';

export interface UserPrices {
  userPrices: Prices[];
  fetchUserPricesError: Error;
  isUserPricesLoading: boolean;
  fetchUserPrices: (data: any[]) => Promise<{data: any}>;
}

export function useUserPrices(): UserPrices {
  const [{ data, error, isUpdating }, fetchUserPrices] = useApi.post('products/prices');

  return {
    userPrices: data,
    fetchUserPricesError: error,
    isUserPricesLoading: isUpdating,
    fetchUserPrices
  };
}
