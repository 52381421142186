import React from 'react';
import PropTypes from 'prop-types';
import styles from './ErrorFeedback.module.scss';

export const ErrorFeedback = (props) => {
  if (!props) {
    return null;
  }

  return props.full
    ? <ErrorFeedbackFull {...props} />
    : <ErrorFeedbackMinimal {...props} />;
};

const ErrorFeedbackFull = (props) => {
  return (
    <div className={styles.ReactComponentFullError}>
      <h3>
        {props.message}
      </h3>

      <pre>
        {JSON.stringify(props.model, null, 2)}
      </pre>
    </div>
  );
};

const ErrorFeedbackMinimal = (props) => {
  return (
    <div className={styles.ReactComponentError}
      title={`Error rendering component: ${props.model?.name ?? 'unknown'}`}>
      <span className="fa fa-times-circle"></span>
    </div>
  );
};

ErrorFeedback.propTypes = {
  model: PropTypes.object,
  full: PropTypes.bool,
  message: PropTypes.string
};
