import * as React from 'react';
import { Provider } from 'react-redux';
import store from '@state/store';
import { ProductTileList } from './ProductTileList';
import type { ProductTileListingProps } from './ProductTileList';

export const ProvideProductTileList = (props: ProductTileListingProps) => {
  return (
    <Provider store={store} >
      <ProductTileList {...props} />
    </Provider>
  );
};
