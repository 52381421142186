import React from 'react';
import { ScReact } from '../ScReact';
import { ScProps } from '../ScProps';
import { BackToOverviewData } from './BackToOverviewData';

export const BackToOverview = (props: ScProps<BackToOverviewData>) => {
  const { url, title } = props.data;

  return <ScReact {...props}>
    <span className="fa fa-md fa-long-arrow-left text-theme text-lg mr-2"></span>
    <a href={url} className="text-sans-serif text-gray-900">{title}</a>
  </ScReact>;
};
