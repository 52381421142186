import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { mapProductData } from '@utils/mapProductsData';
import { context } from '@services/meta-context';
import { useUserPrices } from '@components-react/ProductTile/useUserPrices';
import { ProductTile } from '@components-react/ProductTile/ProductTile';
import styles from '@components-react/ProductTile/ProductTileList.module.scss';

import type { Product, Variant, UnitOfMeasure, Media as MediaData } from '@sctypes/product/Product';
import type { Prices } from '@sctypes/product/Prices';


export interface IProductTileList {
  phrases?: any;
  fallbackImage?: MediaData;
  products?: Product[];
  showAddToCartButton?: boolean;
}

interface VariantUomCodes {
  UnitOfMeasureCode: string;
  VariantCode: string;
}


export const ProductTileList = ({
  products,
  fallbackImage,
  phrases,
  showAddToCartButton = true
}: IProductTileList) => {
  const { fetchUserPrices } = useUserPrices();
  const [prices, setPrices]: [any, (prices: any) => void] = useState({});

  useEffect(() => {
    if (context.user.isLoggedIn) {
      const variantProductMapping = new Map<string, string>();

      // Check if Prices has a key for [product.code]
      const productsWithoutPricing = products.filter(
        (product) => !Object.prototype.hasOwnProperty.call(prices, product.code)
      );

      const unitsOfMeasureCodes: VariantUomCodes[] = productsWithoutPricing
        .reduce((uomCode: VariantUomCodes[][], product: Product) => {
          const variantUomCodes: VariantUomCodes[][] = product.variants.map(
            (variant: Variant) =>
              variant.unitsOfMeasure.map((uom: UnitOfMeasure) => {
                variantProductMapping.set(variant.code, product.code);

                return {
                  VariantCode: variant.code,
                  UnitOfMeasureCode: uom.code
                };
              })
          );

          return uomCode.concat(variantUomCodes);
        }, [])
        .flat();

      unitsOfMeasureCodes.length > 0 &&
        fetchUserPrices(unitsOfMeasureCodes).then((priceData: any) => {
          const newPrices: any = { ...prices };
          priceData.data.map((prices: Prices) => {
            if (!variantProductMapping.get(prices.variantCode)) {
              console.log(
                `VariantCode not found in variantProductMapping: ${prices.variantCode}`
              );
              return;
            }

            const productCode = variantProductMapping.get(prices.variantCode);
            if (!newPrices[productCode]) {
              newPrices[productCode] = {};
            }

            const key = `${prices.variantCode}:${prices.unitOfMeasureCode}`;
            newPrices[productCode][key] = prices;
          });

          setPrices(newPrices);
        });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [products]);

  if (!products) {
    return null;
  }

  return (
    <div className={`${styles.ProductTileList}`}>
      {products.map((product: Product) => {
        const mappedProduct = mapProductData(product);
        if (!mappedProduct?.variant) {
          return null;
        }

        return (
          <ProductTile
            product={mappedProduct}
            key={mappedProduct.variant.code}
            phrases={phrases}
            prices={prices[mappedProduct.code]}
            fallbackImage={fallbackImage}
            showAddToCartButton={showAddToCartButton}
          />
        );
      })}
    </div>
  );
};



const mapStateToProps = (state: any, ownProps?: any) => {
  const products = state?.productSearch?.entities?.results ?? [];
  return {
    ...ownProps,
    products
  };
};


export const ConnectedProductTileList = connect(mapStateToProps)(ProductTileList);
