import React from 'react';
import { ScReact } from '../ScReact';
import { ScProps } from '../ScProps';
import styles from './CustomerRating.module.scss';
import { CustomerRatingData } from './CustomerRatingData';

export const CustomerRating = (props: ScProps<CustomerRatingData>) => {
  const { link, icon, text, score, reviewCount = 0, sectorLogo, phone } = props.data;

  const structuredData = {
    '@context': 'https://schema.org/',
    '@type': 'AggregateRating',
    'itemReviewed': {
      '@type': 'Organization',
      'image': sectorLogo,
      'name': 'Carel Lurvink',
      'url': 'https://www.carellurvink.nl',
      'telephone': phone,
      'address': {
        '@type': 'PostalAddress',
        'streetAddress': 'Marssteden 40',
        'addressLocality': 'Enschede',
        'addressRegion': 'Overijssel',
        'postalCode': '7547 TC',
        'addressCountry': 'NL'
      }
    },
    'ratingValue': score,
    'bestRating': 10,
    'ratingCount': reviewCount
  };


  return <ScReact {...props} className={styles.CustomerRatings}>
    <div className={styles.customerRatings}>
      <a href={link.href} target="_new" dangerouslySetInnerHTML={{__html: icon.render}} />

      <a href={link.href} target="_new">
        {text}
      </a>

      <span className="score">
        {score}/10.0
      </span>
      <script type="application/ld+json" dangerouslySetInnerHTML={{ __html: JSON.stringify(structuredData) }} />
    </div>
  </ScReact>;
};
