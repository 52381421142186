import * as React from 'react';

export interface IProductCount {
  total: number;
  phrases: any;
}


export const ProductCount = (props: IProductCount) => {
  const { total, phrases } = props;
  if (!total) {
    return null;
  }

  const phrase = total === 1 ? phrases.product : phrases.products;

  return (
    <h3 className="h2 mb-0"> {total} {phrase}</h3>
  );
};
