import { connect } from 'react-redux';
import { selectFilterBadges } from '../stores/selectors';
import { getProducts } from '../stores/productsListingSlice';
import { FilterBadges } from '../Components/FilterBadges/FilterBadges';
import type { IBadge, IFilterBadges } from '../Components/FilterBadges/FilterBadges';

export const mapStateToProps = (state: any, ownProps: IFilterBadges) => {
  const activeFilters = selectFilterBadges(state);

  const badges = activeFilters ?? ownProps?.items;
  return {
    ...ownProps,
    items: badges,
    phrases: ownProps?.phrases ?? {}
  };
};

export const mapDispatchToProps = (dispatch: any) => {
  return {
    onFilterBadgeClick: (badge: IBadge) => getProducts(dispatch)({
      apiUrl: badge.urlSet.api,
      webUrl: badge.urlSet.web
    })
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FilterBadges);
