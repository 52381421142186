import * as React from 'react';
import { withScProps } from '@components-react/WithScProps/WithScProps';
import { UrlSet } from '@sctypes/product/UrlSet';
import styles from './FilterBadges.module.scss';
import type { CodeValue } from '@sctypes/product/Product';

export interface IBadge {
  code: CodeValue;
  value: CodeValue;
  urlSet: UrlSet;
}

export interface IFilterBadges {
  items: IBadge[];
  phrases: any; // These should not live on props
  onFilterBadgeClick: (arg0: IBadge) => void
}

export const FilterBadges = ({ items, phrases, onFilterBadgeClick }: IFilterBadges) => {
  if (!items || items.length === 0) {
    return null;
  }

  const handleBadgeClick = async (e: React.MouseEvent<HTMLButtonElement>, badge: IBadge) => {
    e.preventDefault();
    onFilterBadgeClick(badge);
  };

  return (
    <div className={styles.FilterBadgeList}>
      <span className="text-color-basic text-nowrap mb-1 mr-2">
        {phrases.active_filters}
      </span>
      {items.map((badge: IBadge) => {
        return (
          <div className={styles.FilterBadge} key={`${badge.code.code}:${badge.value.code}`}>
            <button
              className="badge btn bg-theme-2 text-white mr-2"
              role="button"
              // @ts-ignore
              rel={badge.rel}
              onClick={(e: React.MouseEvent<HTMLButtonElement>) => handleBadgeClick(e, badge)}
            >
              <span className={styles.code}>{badge.code.value}: </span>
              <span className={styles.value}>{badge.value.value}</span>
              <span className="fa fa-times ml-2"></span>
            </button>
          </div>
        );
      })}
    </div>
  );
};

export default withScProps(FilterBadges);
