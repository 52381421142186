import React from 'react';
import { Provider } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import store from '@state/store';
import 'react-toastify/dist/ReactToastify.css';

export const ProvideToastContainer = ()=> {

  return (
    <Provider store={store} >
      <ToastContainer position="top-center" />
    </Provider>
  );
};
