import * as React from 'react';
import { Srcset } from '@components-react/Srcset/Srcset';
import { ScReact } from '../ScReact';
import { ScProps } from '../ScProps';
import styles from './CallToActionLargeVariants.module.scss';
import { CallToActionLargeVariantsData } from './CallToActionLargeVariantsData';

export const CallToActionLargeVariants = (props: ScProps<CallToActionLargeVariantsData>) => {
  const { data } = props;

  return <ScReact {...props} className={styles.CallToActionLargeVariants}>
    <div className={styles.header}>
      <h2 className={`${styles.title} h1`} dangerouslySetInnerHTML={{ __html: data.title }} />
      <h3 className={`${styles.subtitle} h2`} dangerouslySetInnerHTML={{ __html: data.subtitle }} />
    </div>
    <div className="row">
      <div className={`col-12 col-md-6 d-grid place-items-center ${data.imageOrientation}`}>
        <Srcset image={data.image} />
      </div>
      <div className={`col-12 col-md-6 align-self-center ${data.buttonOrientation}`}>
        {data.buttons.map((button, i) =>
          <a key={i} href={button.link.href} type="button" className={styles.button}>
            <div className="row">
              <div className="col-2 px-0 text-center">
                <img className={styles.buttonImage} src={button.image.src} />
              </div>
              <div className="col d-grid">
                <div className={styles.buttonTitle}
                  dangerouslySetInnerHTML={{ __html: button.title }} />
                <div className={styles.buttonText}
                  dangerouslySetInnerHTML={{ __html: button.text }} />
              </div>
            </div>
          </a>
        )}
      </div>
    </div>
  </ScReact>;
};
