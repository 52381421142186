/**
 * Stub for https://github.com/i18next/react-i18next/blob/master/src/useTranslation.js
 * for when i18Next will be introduced
 */

export function useTranslation(phrases = undefined) {
  function translate(key) {

    if(typeof phrases !== 'undefined') {
      return phrases[key] ?? `[T:${key}]`;
    }

    return `[T:${key}]`;
  }
  return {
    t: translate,
    i18n: stub => stub,
    ready: true
  };
}
