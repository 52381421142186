import { useState } from 'react';
import { useBrowserLayoutEffect } from '@hooks/useBrowserLayoutEffect';
import { defaultConstraints } from '../misc/defaultConstraints';


const useMediaDevicesHook = (constraints?: MediaTrackConstraints) => {
  const [state, setState] = useState<MediaTrackSettings[]>([]);

  useBrowserLayoutEffect(() => {
    let mediaStream: MediaStream;

    const newConstraints: MediaStreamConstraints = {
      audio: false,
      video: constraints ?? defaultConstraints
    };

    const onChange = () => {
      try {
        navigator.mediaDevices
          .getUserMedia(newConstraints)
          .then((stream) => {
            const settings: Array<MediaTrackSettings> = [];
            mediaStream = stream;
            stream.getVideoTracks().forEach((track) => {
              settings.push(track.getSettings());
            });

            setState(settings);
          });

      } catch(error) {
        console.error(error);
      }
    };

    navigator?.mediaDevices?.addEventListener('devicechange', onChange);
    onChange();

    return () => {
      navigator?.mediaDevices?.removeEventListener('devicechange', onChange);

      if(typeof mediaStream !== 'undefined') {
        mediaStream.getVideoTracks().forEach((track) => {
          track.stop();
        });
      }
    };
  }, []);

  return state;
};

export const useMediaDevices = useMediaDevicesHook;
