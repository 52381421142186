import { connect } from 'react-redux';
import { selectPaginationItems, selectPaginationRelative, selectProductsListingLoading } from '../stores/selectors';
import { getProducts } from '../stores/productsListingSlice';
import { Pagination } from '../Components/Pagination/Pagination';
import type { IPagination, IPaginationItem } from '../Components/Pagination/Pagination';

export const mapStateToProps = (state: any, ownProps: IPagination) => {
  const loadingState = selectProductsListingLoading(state);
  // Use client state
  if(loadingState === 'fulfilled') {
    const stateItems = selectPaginationItems(state);
    const relative = selectPaginationRelative(state);
    const items: IPaginationItem[] = stateItems;
    const next: IPaginationItem = relative?.next;
    const prev: IPaginationItem = relative?.prev;

    return {
      items,
      relative: {
        next,
        prev
      }
    };
  }
  // Use server props

  return {
    ...ownProps
  };
};


const mapDispatchToProps = (dispatch: any) => {
  return {
    onPaginationChange: (paginationItem: IPaginationItem) => getProducts(dispatch)({
      apiUrl: paginationItem.urlSet.api,
      webUrl: paginationItem.urlSet.web
    })
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Pagination);
