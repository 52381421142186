import * as React from 'react';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { ProductData, VariantOption } from '@utils/mapProductsData';
import { addToCart } from '@components-react/Cart/cartSlice';
import { selectDisplayTax } from '@components/Preferences/preferencesSlice';
import { useBreakpoint } from '@hooks/useBreakpoint';
import { Labels } from './Components/Labels';
import { Media } from './Components/Media';
import { VariantOptions } from './Components/VariantOptions';
import styles from './ProductTile.module.scss';
import type { CartItem } from '@sctypes/product/CartItem';
import type {
  Media as MediaData,
  UnitOfMeasure,
  Variant
} from '@sctypes/product/Product';


export const ProductTile = ({
  product,
  prices,
  phrases = {},
  fallbackImage,
  showAddToCartButton = true
}: {
  product: ProductData;
  prices: any;
  phrases: any;
  fallbackImage: MediaData;
  showAddToCartButton: boolean;
}) => {
  const dispatch = useDispatch();
  const displayTax = useSelector((state) => selectDisplayTax(state));

  const breakpoint = useBreakpoint();
  const isSmallScreen = ['xs', 'sm', 'md'].includes(breakpoint);

  const variants: Variant[] = product.variants;


  const [currentVariant, setCurrentVariant]: [
    Variant,
    (value: Variant) => void
  ] = useState(
    variants.find((variant) => variant.code === product.defaultVariantCode)
  );
  const [currentUnitOfMeasure, setCurrentUnitOfMeasure]: [
    UnitOfMeasure,
    (value: UnitOfMeasure) => void
  ] = useState(currentVariant?.unitsOfMeasure[0]);
  const [currentUnitOfMeasureDescription, setCurrentUnitOfMeasureDescription]: [
    string,
    (value: string) => void
  ] = useState(currentVariant?.unitsOfMeasure[0].description);
  const [optionCode1, setOptionCode1]: [string, (value: string) => void] =
    useState(product.optionCode1 || '');
  const [optionCode2, setOptionCode2]: [string, (value: string) => void] =
    useState(product.optionCode2 || '');
  const [availableOptionCodes1, setAvailableOptionCodes1]: [
    VariantOption[],
    (value: VariantOption[]) => void
  ] = useState(product.availableOptionCodes1 || []);
  const [availableOptionCodes2, setAvailableOptionCodes2]: [
    VariantOption[],
    (value: VariantOption[]) => void
  ] = useState(product.availableOptionCodes2 || []);
  const [orderAmount, setOrderAmount]: [number, (value: number) => void] =
    useState(1);

  const variantUnitsOfMeasure: UnitOfMeasure[] =
    currentVariant?.unitsOfMeasure || [];

  const optionCode1Value: VariantOption = availableOptionCodes1.find(
    (option: VariantOption) => option.value.code === optionCode1
  );
  const optionCode2Value: VariantOption = availableOptionCodes2.find(
    (option: VariantOption) => option.value.code === optionCode2
  );

  const hasVariantOptions: boolean =
    availableOptionCodes1.length > 0 || availableOptionCodes2.length > 0;
  const hasMultipleUnitsOfMeasure: boolean =
    variantUnitsOfMeasure?.length > 1 || false;

  if (optionCode2Value?.disabled) {
    const availableItems = availableOptionCodes2.filter(
      (item) => !item.disabled
    );
    if (availableItems.length > 0) {
      setOptionCode2(availableItems[0].value.code);
    }
  }

  useEffect(() => {
    const nextVariant: Variant = variants.find(
      (variant: any) =>
        variant.optionCode1 === optionCode1 &&
        variant.optionCode2 === optionCode2
    );
    nextVariant && setCurrentVariant(nextVariant);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [optionCode1, optionCode2]);

  useEffect(() => {
    if (!currentVariant) {
      return;
    }

    let newUnit: UnitOfMeasure = currentVariant.unitsOfMeasure[0];
    if (currentUnitOfMeasure) {
      const unitWithSameQuantity: UnitOfMeasure =
        currentVariant.unitsOfMeasure.find((uom: any) => {
          return uom.itemsPerUnit === currentUnitOfMeasure.itemsPerUnit;
        });

      if (unitWithSameQuantity) {
        newUnit = unitWithSameQuantity;
      }
    }

    setCurrentUnitOfMeasure(newUnit);
    doUnitDescription(newUnit);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentVariant]);

  const handleOptionCodeSelect = (
    optionCode: string,
    optionValue: VariantOption
  ) => {
    if (optionCode === 'optionCode1') {
      const availableVariants: Variant[] = variants.filter(
        (variant: Variant) => variant.optionCode1 === optionValue.value.code
      );
      const validOptionCodes2: string[] = [
        ...new Set(
          availableVariants.map((variant: Variant) => variant.optionCode2)
        )
      ];
      const updatedOptionCodes2: VariantOption[] = availableOptionCodes2.map(
        (code: VariantOption) => ({
          ...code,
          disabled: !validOptionCodes2.includes(code.value.code)
        })
      );
      setAvailableOptionCodes2(updatedOptionCodes2);

      setOptionCode1(optionValue.value.code);
    } else {
      const availableVariants: Variant[] = variants.filter(
        (variant: Variant) => variant.optionCode2 === optionValue.value.code
      );
      const validOptionCodes1: string[] = [
        ...new Set(
          availableVariants.map((variant: Variant) => variant.optionCode1)
        )
      ];
      const updatedOptionCodes1: VariantOption[] = availableOptionCodes1.map(
        (code: VariantOption) => ({
          ...code,
          disabled: !validOptionCodes1.includes(code.value.code)
        })
      );
      setAvailableOptionCodes1(updatedOptionCodes1);
      setOptionCode2(optionValue.value.code);
    }
  };

  const handleUnitOfMeasureChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const unitOfMeasureCode: string = event.target.value;
    const nextUnitOfMeasure: UnitOfMeasure = currentVariant.unitsOfMeasure.find(
      (unitOfMeasure: UnitOfMeasure) => unitOfMeasure.code === unitOfMeasureCode
    );
    setCurrentUnitOfMeasure(nextUnitOfMeasure);
    doUnitDescription(nextUnitOfMeasure);
  };

  const doUnitDescription = (unit: UnitOfMeasure) => {
    if (unit?.description && unit.itemsPerUnit > 1) {
      const pricePerUnit: number = displayTax
        ? unit.prices.current.incTax.numeric
        : unit.prices.current.exTax.numeric;

      const pricePerSingle: number = pricePerUnit / unit.itemsPerUnit;
      const formattedPricePerSingle = pricePerSingle.toLocaleString('nl-NL', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      });

      setCurrentUnitOfMeasureDescription(
        unit.description.replace('#PRICE#', `€ ${formattedPricePerSingle}`)
      );
    } else {
      setCurrentUnitOfMeasureDescription(unit.description);
    }
  };

  const handleOrderAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setOrderAmount(parseInt(e.target.value, 10));
  };

  function getPhrases(): IPhrases {
    let phrases = {
      Phrases: {}
    };
    try {
      phrases = JSON.parse(document.querySelector('[data-component=add-to-cart]').textContent);
    } catch (e) {
      // Do nothing
    }

    return phrases?.Phrases;
  }


  const handleAddToCart = () => {


    if (orderAmount === 0 || isNaN(orderAmount)) {
      const phrase = getPhrases();
      toast(phrase.AddToCart_NoProductMessage);
    }

    if (currentVariant && currentUnitOfMeasure && orderAmount) {
      const cartItem: CartItem[] = [
        {
          orderAmount,
          variantCode: currentVariant.code,
          unitOfMeasureCode: currentUnitOfMeasure.code,
          currentVariant
        }
      ];
      addToCart(dispatch)(cartItem);
    }
  };

  const priceKey = `${currentVariant?.code}:${currentUnitOfMeasure?.code}`;
  const effectivePrice =
    prices && prices[priceKey]
      ? prices[priceKey]
      : currentUnitOfMeasure?.prices;

  const currentMedia: MediaData[] = currentVariant
    ? [...currentVariant.media]
    : [];
  if (currentMedia.length === 0) {
    currentMedia.push(fallbackImage);
  }

  return (
    <div className={`${styles.ProductTile}`}>
      <div className={`${styles.componentContent} px-3`}>
        {product.labels.length > 0 && <Labels labels={product.labels} />}
        <div className={`${styles.ImageComponent}`}>
          <a href={currentVariant?.url ?? product.url}>
            <Media media={currentMedia} alt={currentVariant.title} />
          </a>
        </div>

        <div className={`${styles.Meta}`}>
          <a href={currentVariant?.url ?? product.url}>
            <h3 className={`${styles.h3} h3 mb-0`}>
              {currentVariant?.title ?? product.title}
            </h3>
          </a>
          <p className="text-sans-serif text-muted mb-3">
            <a href={currentVariant?.url ?? product.url}>
              {phrases.productCode}{' '}
              <span>{currentVariant?.code ?? product.code}</span>
            </a>
          </p>
        </div>

        {effectivePrice && (
          <div className={`${styles.Prices}`}>
            <a href={currentVariant?.url ?? product.url}>
              <span
                className={`${styles.PriceLabel}`}
                title={currentUnitOfMeasureDescription}
              >
                <span className="h3">
                  {displayTax
                    ? effectivePrice.current.incTax.formatted
                    : effectivePrice.current.exTax.formatted}
                </span>
                <span className="ml-2">{currentUnitOfMeasureDescription}</span>
              </span>

              <p className="text-sans-serif text-xs text-muted">
                {displayTax ? phrases.incTax : phrases.exTax}
              </p>
            </a>
          </div>
        )}

        {!isSmallScreen && (
          <>
            {hasVariantOptions && (
              <div className={styles.VariantOptionsContainer}>
                {availableOptionCodes1.length > 0 && (
                  <VariantOptions
                    items={availableOptionCodes1}
                    onOptionCodeSelect={handleOptionCodeSelect}
                    optionCode="optionCode1"
                    type={availableOptionCodes1[0].typeCode}
                    variant={optionCode1Value}
                    allowDisable={false}
                  />
                )}
                {availableOptionCodes2.length > 0 && (
                  <VariantOptions
                    items={availableOptionCodes2}
                    onOptionCodeSelect={handleOptionCodeSelect}
                    optionCode="optionCode2"
                    type={availableOptionCodes2[0].typeCode}
                    variant={optionCode2Value}
                    allowDisable={true}
                  />
                )}
              </div>
            )}
            {showAddToCartButton && (
              <div className={`${styles.AddToCart} row pb-3 mx-0`}>
                <div className="col pl-0 pr-1">
                  {hasMultipleUnitsOfMeasure && (
                    <select
                      className={`${styles.AddToCartAmountUOM} form-control custom-select`}
                      value={currentUnitOfMeasure.code}
                      onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
                        handleUnitOfMeasureChange(e)
                      }
                    >
                      {variantUnitsOfMeasure?.length > 0 &&
                        variantUnitsOfMeasure.map((uom: any) => {
                          return (
                            <option value={uom.code} key={uom.code}>
                              {uom.name}
                            </option>
                          );
                        })}
                    </select>
                  )}
                  {!hasMultipleUnitsOfMeasure && currentUnitOfMeasure && (
                    <input
                      className={`${styles.AddToCartAmount} form-control custom-control`}
                      value={currentUnitOfMeasure.name}
                      readOnly
                    />
                  )}
                </div>

                <div className="col pr-0 pl-1">
                  <div className="input-group">
                    <input
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        handleOrderAmountChange(e)
                      }
                      value={orderAmount}
                      type="number"
                      min="1"
                      className={`form-control ${styles.AddToCartAmount} pr-0`}
                      name="amount"
                      autoComplete="off"
                      placeholder="Aantal"
                      aria-label="aantal"
                    />
                    <div className="input-group-append">
                      <button
                        className={`${styles.AddToCartButton} btn btn-cart`}
                        type="button"
                        onClick={handleAddToCart}
                      >
                        <i className="fa cl-cart fa-lg"></i>
                        <span className="sr-only">{phrases.addToCart}</span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};
