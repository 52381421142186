import React, { useEffect, useState } from 'react';
import { useOverflowHidden } from '@hooks/useOverflowHidden';
import { useBrowserLayoutEffect } from '@hooks/useBrowserLayoutEffect';
import styles from './FullPageScrollPane.module.scss';

interface IFullPageScrollPane {
  PageHeader?: React.ReactNode;
  PageFooter?: React.ReactNode;
  children: React.ReactNode;
  isOpen?: boolean;
  addContainer?: boolean;
}

export const FullPageScrollPane = ({
  PageHeader,
  PageFooter,
  children,
  isOpen
}: IFullPageScrollPane) => {

  // Make sure this component is client only to prevent hydration errors
  const [showChild, setShowChild] = useState(false);
  useEffect(() => {
    // TODO: "use client" in NextJS 13 + `app` directory
    setShowChild(true);
  }, []);


  useOverflowHidden(isOpen);

  useBrowserLayoutEffect(() => {
    if(isOpen) {
      document.body.classList.toggle('isFullPageScrollpaneOpen');
    }
    return () => document.body.classList.remove('isFullPageScrollpaneOpen');
  }, [isOpen]);


  if (!showChild) {
    return null;
  }

  return (
    <div className={`${styles.fullPage} ${isOpen ? styles.isOpen : ''}`}>
      {PageHeader && (
        <div className={styles.fullPageHeader}>
          {PageHeader}
        </div>
      )}

      <div className={styles.fullPageScrollPane}>
        <div className={styles.fullPageScrollPaneContent}>
          { children }
        </div>
      </div>


      {PageFooter && (
        <div className={styles.fullPageFooter}>
          { PageFooter }
        </div>
      )}
    </div>
  );
};
