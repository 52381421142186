import { connect } from 'react-redux';
import { selectTotal } from '../stores/selectors';
import { ProductCount } from '../Components/ProductCount/ProductCount';
import type { IProductCount } from '../Components/ProductCount/ProductCount';

export const mapStateToProps = (state: any, ownProps: IProductCount) => {
  const totalState = selectTotal(state);

  const total: number = totalState ?? ownProps?.total;
  return {
    ...ownProps,
    total: total,
    phrases: ownProps?.phrases ?? {}
  };
};

export default connect(mapStateToProps)(ProductCount);
