export type CallBackFn = (...args: any[]) => any;

export const debounce = (callBackFn: CallBackFn, delay: number) => {
  let timer: ReturnType<typeof setTimeout>;

  return function (args: any) {

    if (timer) {
      clearTimeout(timer);
    }

    timer = setTimeout(() => {
      timer = null;
      callBackFn.call(args);
    }, delay);
  };
};
