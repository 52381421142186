import * as React from 'react';
import { Provider } from 'react-redux';
import store from '@state/store';
import { ConnectedSearchBox } from '../Connectors/ConnectedSearchBox';
import type { ISearchBox } from '../Components/SearchBox';

export const ProvideSearchBox = (props: ISearchBox) => {
  return (
    <Provider store={store}>
      <ConnectedSearchBox { ...props }/>
    </Provider>
  );
};
