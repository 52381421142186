import * as React from 'react';
import objstr from 'obj-str';
import { ScProps } from './ScProps';

export const ScReact = (props: ScProps<any>) => {
  const cssClassNames = objstr({
    [props.className]: !!props.className,
    component: true,
    [props.renderingCssClass]: !!props.renderingCssClass,
    [props.styles]: !!props.styles
  });

  return (
    <div
      className={cssClassNames}>
      <div className="component-content">
        {props.children}
      </div>
    </div>);
};


/**
 *
 * @param Component React Component
 * @returns
 */

export function withScReact(Component: any) {
  return function WithScReactComponent(scProps: ScProps<any>, ...props: any) {

    const cssClassNames = objstr({
      [scProps.className]: !!scProps.className,
      component: true,
      [scProps.renderingCssClass]: !!scProps.renderingCssClass,
      [scProps.styles]: !!scProps.styles
    });


    const componentProps = {
      ...props,
      ...scProps?.data
    };

    return (
      <div className={cssClassNames}>
        <div className="component-content">
          <Component {...componentProps } />
        </div>
      </div>
    );
  };
}
export default withScReact;
