import * as React from 'react';
import { ScReact } from '../ScReact';
import { ScProps } from '../ScProps';
import './ProductSpecialist.scss';
import { ProductSpecialistData } from './ProductSpecialistData';

export const ProductSpecialist = (props: ScProps<ProductSpecialistData>) => {
  const { data } = props;

  return <ScReact {...props}>
    <div className="product-specialist my-2 col" data-component="product-specialist">
      <div className="row">
        <div className="col justify-content-center mb-3">
          <div className="heading">
            <span className="headingText py-1 px-3">{data.title}</span>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-3 justify-content-start">
          <img className="image" src={data.image} />
        </div>

        <div className="col-9">
          <div className="row mb-2">
            <div className="col">
              <span className="text">{data.subText}</span>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-xl-auto">
              <span className="fa fa-fw fa-envelope-o mr-2"></span>
              <a className="link" href={`mailto:${data.email}`}>
                <span>{data.emailText}</span>
              </a>
            </div>
            <div className="col">
              <span className="fa fa-fw fa-phone mr-2"></span>
              <a className="link" href={`tel:${data.phone}`}>
                <span>{data.phone}</span>
              </a>
            </div>
          </div>

        </div>
      </div>
    </div>
  </ScReact>;
};
