import React from 'react';
import { Swiper, SwiperSlide, Autoplay } from '@lib/swiper';
import { ScReact } from '../ScReact';
import { ScProps } from '../ScProps';
import styles from './CustomerRatings.module.scss';
import { CustomerRatingsData, CustomerRatingCarouselItem } from './CustomerRatingsData';

export const CustomerRatingsCarousel = (props: ScProps<CustomerRatingsData>) => {
  const { list } = props.data;

  return <ScReact {...props} className={styles.CustomerRatingsCarousel}>
    <Swiper
      autoplay={true}
      modules={[Autoplay]}>
      {list.map((item: CustomerRatingCarouselItem) =>
        <SwiperSlide key={item.oneLiner} className={styles.CustomerRatingCarouselItem}>
          <div className="h2 text-gray-900 font-italic text-center mb-2">{item.oneLiner}</div>
          <div className={styles.review}>
            <div className={`text-gray-500 ${styles.reviewAuthor}`}>{item.formattedDate} {item.author}</div>
            <div className={styles.ratingContainer}>
              <span className={styles.ratingBackground}>
                <span className="fa fa-star-o"></span>
                <span className="fa fa-star-o"></span>
                <span className="fa fa-star-o"></span>
                <span className="fa fa-star-o"></span>
                <span className="fa fa-star-o"></span>
              </span>
              <span className={styles.ratingOverlay} style={{ width: `${item.rating * 10}%` }}>
                <span className="fa fa-star"></span>
                <span className="fa fa-star"></span>
                <span className="fa fa-star"></span>
                <span className="fa fa-star"></span>
                <span className="fa fa-star"></span>
              </span>
            </div>
          </div>
        </SwiperSlide>
      )}
    </Swiper>
  </ScReact>;
};
