import * as React from 'react';
import { Provider, useSelector } from 'react-redux';
import { ScReact } from '@components-react/ScReact';
import { mapProductData, ProductData } from '@utils/mapProductsData';
import store from '@state/store';
import { ScProps } from '@components-react/ScProps';
import { ProductAndVariant } from '@sctypes/product/Product';

export const ProductTitleComponent = (props: ScProps<ProductAndVariant>) => {
  const product: ProductData = useSelector((state: any) => state.productDetail)
    ?? mapProductData(props.data.product, props.data.variant);

  const title: string = product?.variant?.title ?? product?.title ?? '[Product Title]';
  const code: string = product?.variant?.code ?? product?.code ?? '[Product Code]';

  return <ScReact {...props}>
    <div className="component title">
      <div className="component-content">
        <h1 className="h1">{title}</h1>
      </div>
    </div>

    <div className="text-gray-500"><span>Art: </span>{code}</div>
  </ScReact >;
};

export const ProductTitle = (props: ScProps<ProductAndVariant>) => (
  <Provider store={store}>
    <ProductTitleComponent {...props} />
  </Provider>
);
