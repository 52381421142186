import { connect } from 'react-redux';
import { Pagination } from '@components-react/ProductOverview/Components/Pagination/Pagination';
import { selectPaginationItems, selectPaginationRelative } from '../stores/selectors';
import { getProducts } from '../stores/productSearchSlice';
import type { IPaginationItem } from '@components-react/ProductOverview/Components/Pagination/Pagination';

export const mapStateToProps = (state: any, ownProps: any) => {
  const items = selectPaginationItems(state);
  const { prev = undefined, next = undefined } = selectPaginationRelative(state);

  const nextProps = {
    ...(items && { items }),
    relative: {
      ...(prev && { prev }),
      ...(next && { next })
    }
  };

  return {
    ...ownProps,
    ...nextProps
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    onPaginationChange: (paginationItem: IPaginationItem) => {

      const nextUrl = `${document.location.origin}${paginationItem.urlSet.web}`;
      window.history.pushState(null, null, nextUrl);

      getProducts(dispatch)({
        apiUrl: paginationItem.urlSet.api
      });
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Pagination);
