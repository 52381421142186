import * as React from 'react';
import { useEffect, useState } from 'react';
import Collapse from 'react-bootstrap/Collapse';
import { useOverflowHidden } from '@hooks/useOverflowHidden';
import styles from './CategoryFacets.module.scss';
import type { UrlSet } from '@sctypes/product/UrlSet';
import type { CodeValue } from '@sctypes/product/Product';

export interface IFacetItem {
  value: CodeValue;
  hits: number;
  urlSet: UrlSet;
  selected: boolean;
}

export interface IFacetItems {
  type: CodeValue;
  items: IFacetItem[];
}

export interface IFacet {
  facet: IFacetItems;
  initialExpanded: boolean;
  onFacetChange: (item: IFacetItem) => any;
  idPrefix: string;
}

const Facet = ({ facet, initialExpanded = false, onFacetChange, idPrefix }: IFacet) => {
  const [isExpanded, setIsExpanded] = useState<boolean>(false);

  useEffect(() => {
    setIsExpanded(initialExpanded);
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={`${styles.CategoryFacetsFilter} px-3 px-lg-0`} key={facet.type.code} data-testid="CategoryFacets:Facet">
      <a
        className={styles.CategoryFacetsFilterToggle}
        data-toggle="collapse"
        role="button"
        aria-expanded={isExpanded}
        aria-controls={`facet-${facet.type.code}`}
        onClick={() => setIsExpanded(!isExpanded)}
      >
        <span className={styles.FacetToggleValue}>
          {facet.type.value }
        </span>
      </a>

      <Collapse in={isExpanded}>
        <div id={`${idPrefix}-${facet.type.code}`}>
          <ul className="mb-3">
            {facet.items.map(item => {
              return (
                <li className="w-100" key={item.value.code}>
                  <label className={styles.FacetLabel} role="button">
                    <input
                      className="custom-checkbox custom-checkbox--themed mr-1"
                      type="checkbox"
                      value={item.urlSet?.api}
                      onChange={() => onFacetChange(item)}
                      // @ts-expect-error: lib.dom
                      rel={item.rel}
                      checked={item.selected}
                    />
                    <span className={styles.FacetLabelValue}> { item.value.value }</span>
                    <span>({ item.hits })</span>
                  </label>
                </li>
              );
            })}
          </ul>
        </div>
      </Collapse>
    </div>
  );
};


export interface ICategoryFacets {
  facets: IFacet[];
  isOpen?: boolean
  onFacetChange: (arg0: IFacetItem) => void
  productCount?: number;
  toggleFacetsOpen: () => void,
  phrases: IPhrases;
  restProps: any;
  idPrefix?: string;
}

export const CategoryFacets = ({
  facets,
  isOpen,
  onFacetChange,
  productCount,
  toggleFacetsOpen,
  phrases,
  idPrefix = 'facet'
}: ICategoryFacets) => {

  const handleFacetChange = async (facetItem: IFacetItem) => {
    onFacetChange(facetItem);
  };


  const productsPhrase = productCount === 1 ? phrases.product : phrases.products;
  const showProductsText = phrases.open_facets_show
    .replace('#COUNT#', String(productCount))
    .replace('#PRODUCTS#', productsPhrase);


  useOverflowHidden(isOpen);

  return (
    <div className={`${styles.CategoryFacets} ${isOpen ? 'isOpen' : ''}`}>
      <div className={`${styles.FacetsModalHeader} d-lg-none p-3`} data-testid="CategoryFacets:ModalHeader">
        <span className="h1">{ phrases.open_facets_title }</span>
        <button
          type="button"
          className="btn btn-link p-0"
          onClick={toggleFacetsOpen}
        >
          <span className="fa fa-times text-theme text-lg mr-1"></span>
          <span className="text-sans-serif text-gray-900 text-nowrap" data-testid="CategoryFacets:CloseButton">
            { phrases.open_facets_close }
          </span>
        </button>
      </div>
      <div className={styles.FacetsModalContent} data-testid="CategoryFacets:ModalContent">

        {facets.map((facet: any) => {
          const hasExpandedItem: boolean = facet.items.findIndex((item: IFacetItem) => item.selected === true) > -1;

          return (
            <Facet
              facet={facet}
              initialExpanded={hasExpandedItem}
              onFacetChange={handleFacetChange}
              key={facet.type.code}
              idPrefix={idPrefix}
            />
          );
        })}
      </div>

      <div className={`${styles.FacetsModalFooter} d-lg-none p-3`}>
        <button
          type="button"
          className="btn btn-primary btn-xl btn-block"
          onClick={toggleFacetsOpen}
        >
          {showProductsText}
        </button>
      </div>
    </div>
  );
};
