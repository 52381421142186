import { connect } from 'react-redux';
import { FilterBadges } from '@components-react/ProductOverview/Components/FilterBadges/FilterBadges';
import { selectFilterBadges } from '../stores/selectors';
import { getProducts } from '../stores/productSearchSlice';
import type { IBadge } from '@components-react/ProductOverview/Components/FilterBadges/FilterBadges';

export const mapStateToProps = (state: any, ownProps?: any) => {
  const activeFilters = selectFilterBadges(state);

  const badges = activeFilters || [];
  return {
    ...ownProps,
    items: badges,
    phrases: ownProps?.phrases ?? {}

  };
};

export const mapDispatchToProps = (dispatch: any) => {
  return {
    onFilterBadgeClick: (badge: IBadge) => getProducts(dispatch)({
      apiUrl: badge.urlSet.api,
      webUrl: badge.urlSet.web
    })
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FilterBadges);
