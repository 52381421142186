import * as React from 'react';
import { ScReact } from '../ScReact';
import { ScProps } from '../ScProps';
import styles from './USP.module.scss';

export const USP = (props: ScProps<string[]>) => (
  <ScReact {...props}>
    <div className={`${styles.uspContainer} d-none d-md-flex`}>
      <ul className={`${styles.uspList}`}>
        {props.data.map((upsItem, i) =>
          <li key={i} className={`${styles.uspItem} ${styles.checkCircle}`} dangerouslySetInnerHTML={{ __html: upsItem }}/>
        )}
      </ul>
    </div>
    {/*mobile only shows 1 name*/}
    <div className={`${styles.uspContainer} d-md-none`}>
      <ul className={`${styles.uspList}`}>
        <li className={`${styles.uspItem} ${styles.checkCircle}`} dangerouslySetInnerHTML={{ __html: props.data[0] }}/>
      </ul>
    </div>
  </ScReact>
);
