import React, { useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { isFulfilled,isRejected } from '@reduxjs/toolkit';
import { useTranslation } from '@hooks/useTranslation';
import { selectDisplayTax } from '@components/Preferences/preferencesSlice';
import { Modal } from '@components-react/Modal/Modal';
import { Media as MediaData, UnitOfMeasure } from '@sctypes/product/Product';
import { ScProps } from '@components-react/ScProps';
import { ScReact } from '@components-react/ScReact';
import { getOrderLists, selectOrderLists, displayOrderListsModal, addItemsToOrderList } from '../state/orderListsSlice';
import { addItemToOrderListsThunk } from '../state/orderListsThunks';
import { IOrderList } from '../types';
import styles from './AddToOrderListModal.module.scss';
import { ErrorMessageModal } from './ErrorMessageModal';
import { SuccessMessageModal } from './SuccessMessageModal';

export interface IToggleButton {
  phrases: IPhrases;
  toggleSelectedLists: any;
}

// Externalize Button to prevent parent Modal from re-rendering when checkboxes are toggled
const ToggleButton = ({ phrases, toggleSelectedLists }: IToggleButton) => {
  const { t } = useTranslation(phrases);
  const [isAllSelected, setIsAllSelected] = useState(false);

  const handleClick = (isSelected: any) => {
    toggleSelectedLists(isSelected);
    setIsAllSelected(isSelected);
  };

  return (
    <>
      {isAllSelected === false && (
        <button
          type="button"
          className={`${styles.selectButton} btn btn-link`}
          onClick={() => handleClick(true)}
        >
          <span>{t('AddToOrderList_SelectAll')}</span>
        </button>
      )}
      {isAllSelected === true && (
        <button
          type="button"
          className={`${styles.selectButton} btn btn-link`}
          onClick={() => handleClick(false)}
        >
          <span>{t('AddToOrderList_SelectNone')}</span>
        </button>
      )}
    </>
  );
};

export const AddToOrderListModal = (props: ScProps<IPhrases>) => {
  const { phrases } = props?.data || {};
  const { t } = useTranslation(phrases);

  const formRef = useRef(null);
  const dispatch = useDispatch();
  const orderListsState = useSelector(state => selectOrderLists(state));
  const lastAction = useSelector((state:any) => state.lastAction);
  const displayTax = useSelector(state => selectDisplayTax(state));

  const { orderLists } = orderListsState;
  const shouldDisplayModal = orderListsState.displayModal;
  const isAddToOrderListSuccess = isFulfilled(addItemToOrderListsThunk)(lastAction);
  const isAddToOrderListFailed = isRejected(addItemToOrderListsThunk)(lastAction);

  const [isShown, setIsShown] = useState(false);
  const isAllSelectedRef = useRef(false);

  useEffect(() => {
    if(shouldDisplayModal){
      getOrderLists(dispatch)();
      setIsShown(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldDisplayModal]);


  const { title = '', code = '', media = [], unitOfMeasureCode, variant } = orderListsState.product;
  const { thumbnail = null } = media?.find((mediaItem: MediaData) => !!mediaItem.thumbnail) || {};

  const doUnitDescription = (unit: UnitOfMeasure) => {
    if(!unit?.description) {
      return;
    }

    if(unit.itemsPerUnit > 1) {
      const pricePerUnit: number = displayTax
        ? unit.prices.current.incTax.numeric
        : unit.prices.current.exTax.numeric;

      const pricePerSingle: number = pricePerUnit / unit.itemsPerUnit;
      const formattedPricePerSingle = pricePerSingle.toLocaleString('nl-NL', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      });

      return unit.description.replace('#PRICE#', `€ ${formattedPricePerSingle}`);
    }
    return unit.description;
  };


  const dismissModal = () => {
    setIsShown(false);
    displayOrderListsModal(dispatch)(false);
  };

  const handleSubmit = (e: any ) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    // See: https://github.com/microsoft/TypeScript/issues/43797
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore

    // Pull in list data from the input element's name value,
    // this negates the need for controlled inputs and greatly simplefies from handling
    const formEntries = Object.fromEntries(formData);
    const selectedLists = Object.keys(formEntries).map((listData: any) => JSON.parse(listData));

    addItemsToOrderList(dispatch)({
      variantCode: variant.code,
      unitOfMeasureCode: unitOfMeasureCode,
      selectedLists
    });
  };


  const toggleSelectedLists = (isSelected: any) => {
    isAllSelectedRef.current = isSelected;

    const checkboxes = formRef?.current.querySelectorAll('[type=checkbox]');
    checkboxes?.forEach((checkbox: any) => {
      checkbox.checked = isSelected;
    });

  };


  if (isAddToOrderListSuccess) {
    const unitOfMeasure = variant.unitsOfMeasure.find((uom: UnitOfMeasure) => uom.code === unitOfMeasureCode);
    const unitDescription = doUnitDescription(unitOfMeasure);
    const price = displayTax ? unitOfMeasure.prices.current.incTax.formatted : unitOfMeasure.prices.current.exTax.formatted;

    const modalProps = {
      dismissModal,
      thumbnail,
      code,
      title,
      price,
      unitDescription,
      displayTax,
      phrases };
    return <SuccessMessageModal { ...modalProps} />;
  }


  if(isAddToOrderListFailed) {
    const modalProps = { dismissModal, thumbnail, code, title, phrases };
    return <ErrorMessageModal { ...modalProps} />;
  }


  if (isShown) {
    const buttons = [{
      type: 'outline-primary',
      label: t('AddToOrderList_Cancel'),
      onClick: dismissModal
    },{
      type: 'primary',
      label: t('AddToOrderList_Add'),
      onClick: () => {
        formRef.current.requestSubmit();
      }
    }];

    const HeaderComponent = () => {
      const unitOfMeasure = variant.unitsOfMeasure.find((uom: UnitOfMeasure) => uom.code === unitOfMeasureCode);
      const unitDescription = doUnitDescription(unitOfMeasure);
      const price = displayTax ? unitOfMeasure.prices.current.incTax.formatted : unitOfMeasure.prices.current.exTax.formatted;

      return (
        <ScReact {...props}>
          <div className={`${styles.modalHeader} border-bottom border-gray-200`}>
            <h1 className="h1 mb-3">{ t('AddToOrderList_ModalTitle')}</h1>
            <div className="d-flex">
              {thumbnail && <div className="mr-3"><img src={thumbnail} className={styles.thumbnail} /></div>}
              <div className="flex-direction-column">
                <h2 className="h3">{title}</h2>
                <div className="text-sans-serif text-gray-500">{t('AddToOrderList_ProductCode')} {code}</div>
                <div className="d-flex flex-row align-items-center">
                  <span className="h3">{price}</span> <span className={styles.priceLabel}>{unitDescription}</span>
                </div>
                <p className="text-sans-serif text-xs text-muted">
                  {(displayTax ? t('AddToOrderList_IncTax') : t('AddToOrderList_ExTax') )}
                </p>
              </div>
            </div>
          </div>
        </ScReact>
      );
    };

    return (
      <Modal
        HeaderComponent={HeaderComponent}
        title={t('AddToOrderList_ModalTitle')}
        buttons={buttons}
        onModalClose={dismissModal}
        hasFooterSeparator
      >
        <div>
          <form onSubmit={handleSubmit} ref={formRef}>
            <div className={`${styles.formHeader}`}>
              <h2 className="h3">{t('AddToOrderList_OrderLists')}</h2>
              <div>
                <button className="d-none" type="button">{t('AddToOrderList_New_OrderList')}</button>
                <ToggleButton phrases={phrases} toggleSelectedLists={toggleSelectedLists} />
              </div>
            </div>
            <ul className={styles.list}>
              {orderLists.map((list: IOrderList) => {
                return (
                  <li className={styles.listItem} key={list.cartName}>
                    <label className="d-flex" role="button">
                      <input type="checkbox" className="mr-3" name={JSON.stringify(list)} />
                      <span>{list.displayValue}</span>
                    </label>
                  </li>
                );
              })}
            </ul>
          </form>
        </div>
      </Modal>
    );
  }

  return null;
};
