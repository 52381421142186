import * as React from 'react';
import { Srcset } from '@components-react/Srcset/Srcset';
import { ScReact } from '../ScReact';
import { ScProps } from '../ScProps';
import styles from './HeroBanner.module.scss';
import { HeroBannerData } from './HeroBannerData';

export const HeroBanner = (props: ScProps<HeroBannerData>) => {
  const { data } = props;
  if (!data.image) {
    return null;
  }
  const hasLink = typeof data.link.href !== 'undefined' && data.link.href;

  return <ScReact {...props} className={styles.HeroBanner}>
    {hasLink && (<a href={data.link?.href} title={data.link?.text}>
      <Srcset image={data.image} />
    </a>
    )}
    {!hasLink && (<Srcset image={data.image} />)}
  </ScReact>;
};
