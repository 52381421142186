import React, { useRef } from 'react';
import objStr from 'obj-str';
import { Swiper, SwiperSlide, Navigation } from '@lib/swiper';
import { mapProductData } from '@utils/mapProductsData';
import { ProductTile } from '../ProductTile';
import styles from './SwipeableProductTileList.module.scss';
import type { Product, Media } from '@sctypes/product/Product';
import type { KeyValuePair } from '@sctypes/KeyValuePair';

export interface SwipableProductTileListProps {
  fallbackImage?: Media;
  hideGliderNavigation: boolean;
  phrases?: KeyValuePair[];
  prices: any;
  products: Product[];
  slidesPerView: number;
  title: string;
  showAddToCartButton?: boolean;
}

export const SwipableProductTileList = (props: SwipableProductTileListProps) => {
  const swiperRef = useRef(null);

  const {
    fallbackImage,
    hideGliderNavigation = false,
    phrases,
    prices,
    products,
    slidesPerView,
    title,
    showAddToCartButton = true
  } = props;

  const cssClassNames = objStr({
    ['component-content']: true,
    [styles.SwipeableProductTileList]: true
  });

  return (
    <div className="product-listing-wrapper">
      {title && <h2 className="h2" dangerouslySetInnerHTML={{ __html: title }} />}
      <div className={cssClassNames}>
        <Swiper
          breakpoints={{
            0: {
              slidesPerView: 1
            },
            768: {
              slidesPerView
            }
          }}
          modules={[Navigation]}
          height={460}
          spaceBetween={16}
          navigation={{
            lockClass: 'd-none',
            disabledClass: 'disabled',
            nextEl: `.swiper-button-next.${styles.btnListSlide}`,
            prevEl: `.swiper-button-prev.${styles.btnListSlide}`
          }}
          onSwiper={(swiper) => {
            swiperRef.current = swiper;
          }}
        >
          {products.map((product: Product, idx: number) => {
            const mappedProduct = mapProductData(product);
            if (!mappedProduct?.variant) {
              return null;
            }

            return (
              <SwiperSlide key={`${product.code}-${idx}`}>
                <ProductTile
                  product={mappedProduct}
                  key={product.code}
                  prices={prices[product.code]}
                  phrases={phrases}
                  fallbackImage={fallbackImage}
                  showAddToCartButton={showAddToCartButton}
                />
              </SwiperSlide>
            );
          })}

          {!hideGliderNavigation &&
              <div className={styles.buttons}>
                <button
                  className={`swiper-button-prev btn btn-square ${styles.btnListSlide}`}
                  onClick={() => swiperRef.current.slidePrev()}
                >
                  <span className="sr-only">prev</span>
                </button>

                <button
                  className={`swiper-button-next btn btn-square btn-list-slide ${styles.btnListSlide}`}
                  onClick={() => swiperRef.current.slideNext()}
                >
                  <span className="sr-only">next</span>
                </button>
              </div>
          }

        </Swiper>
      </div>
    </div>
  );
};
