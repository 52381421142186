import React from 'react';
import { ScReact } from '../ScReact';
import { ScProps } from '../ScProps';
import styles from './HorizontalLine.module.scss';

export const HorizontalLine = (props: ScProps<any>) => {
  return <ScReact {...props} className={styles.HorizontalLine}>
    <div className="component-content">
      <hr/>
    </div>
  </ScReact>;
};
