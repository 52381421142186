import { createSelector } from '@reduxjs/toolkit';

export const selectProductsListingEntities = state => state?.productsListing?.entities;
export const selectProductsListingLoading = state => state?.productsListing?.loading;

export const selectFacetsOpen = createSelector(
  state => state,
  state => state?.productsListing?.facetsOpen
);

export const selectSortOrder = createSelector(
  selectProductsListingEntities,
  state => state && state['sort'] // `sort` is a reserved word in JS
);

export const selectPagination = createSelector(
  selectProductsListingEntities,
  state => state?.pagination
);

export const selectPaginationItems = createSelector(
  selectProductsListingEntities,
  state => state?.pagination?.items
);

export const selectPaginationRelative = createSelector(
  selectProductsListingEntities,
  state => state?.pagination?.relative
);


export const selectFilterBadges = createSelector(
  selectProductsListingEntities,
  state => state?.active
);

export const selectProducts = createSelector(
  selectProductsListingEntities,
  state => state?.results
);

export const selectTotal = createSelector(
  selectProductsListingEntities,
  state => state?.total
);

export const selectFacets = createSelector(
  selectProductsListingEntities,
  state => state?.facets
);

export const selectActiveFacets = createSelector(
  selectFacets,
  allFacets => {
    const activeFacets = allFacets.filter(facet => {
      return facet.items.findIndex(item => item.selected === true) !== -1;
    });
    return activeFacets || [];
  }
);

export const selectActiveFacetItems = createSelector(
  selectActiveFacets,
  activeFactes => {
    return activeFactes.map(facet => {
      const selectedItems = facet.items.filter(item => item.selected === true);
      return {
        ...facet,
        items: selectedItems
      };
    });
  }
);
