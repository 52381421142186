import React from 'react';
import { ScReact } from '../ScReact';
import { ScProps } from '../ScProps';
import styles from './Breadcrumb.module.scss';
import { BreadcrumbData, BreadcrumbItem } from './BreadcrumbData';

export const Breadcrumb = (props: ScProps<BreadcrumbData>) => {
  const { items, schema } = props.data;

  return <ScReact {...props} className={styles.Breadcrumb}>
    <ol className="d-flex">
      {items.map((breadcrumb: BreadcrumbItem, index: number) => (
        <li
          key={`breadcrumb-${index}`}
          className={`${styles.breadcrumbItem} ${index === items.length - 1 ? styles.breadcrumbLastItem : ''}`}
        >
          <a className={styles.breadcrumbItemLink} href={breadcrumb.url}>
            <span className={styles.breadcrumbName}>{breadcrumb.name}</span>
          </a>
        </li>
      ))}
    </ol>
    <script type="application/ld+json" dangerouslySetInnerHTML={{ __html: schema }} />
  </ScReact>;
};
