import { useEffect, useMemo, useRef } from 'react';
import { debounce, type CallBackFn } from '@utils/debounce';

export const useDebounce = (callBackFn: CallBackFn, delay: number) => {
  const ref = useRef(callBackFn);

  useEffect(() => {
    ref.current = callBackFn;
  }, [callBackFn]);

  const debouncedCallback = useMemo(() => {
    const func = () => {
      ref.current();
    };

    return debounce(func, delay);
  }, [delay]);

  return debouncedCallback;
};
