import React, { useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { useBrowserLayoutEffect } from '@hooks/useBrowserLayoutEffect';

interface IPortalProps {
  children?: React.ReactNode;
  selector?: string;
}


export const Portal = ({ children, selector = '#portal' }: IPortalProps) => {
  const nodeRef: any = useRef(null);
  const [mounted, setMounted] = useState(false);

  useBrowserLayoutEffect(() => {
    nodeRef.current = document.querySelector(selector);
    !!nodeRef.current && setMounted(true);

    return () => setMounted(false);
  }, [selector]);

  return mounted ? createPortal(children, nodeRef.current) : null;
};
