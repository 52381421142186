import * as React from 'react';
import { useState } from 'react';
import { Provider, useDispatch, useSelector} from 'react-redux';
import { toast } from 'react-toastify';
import { addToCart } from '@components-react/Cart/cartSlice';
import { ScReact } from '@components-react/ScReact';
import store from '@state/store';
import './ProductDetailAddToCart.scss';
import { CartItem } from '@sctypes/product/CartItem';
import { ScProps } from '@components-react/ScProps';

interface AddToCartProps {
  amount: string;
  addToCart: string;
  enabled: boolean;
}

export const ProductDetailAddToCartComponent = (props: ScProps<AddToCartProps>) => {
  const dispatch = useDispatch();
  const { data } = props;
  const [orderAmount, setOrderAmount]: [number, (value: number) => void] = useState(1);
  const product = useSelector((state: any) => state.productDetail);
  function getPhrases(): IPhrases {
    let phrases = {
      Phrases: {}
    };
    try {
      phrases = JSON.parse(document.querySelector('[data-component=add-to-cart]').textContent);
    } catch (e) {
      // Do nothing
    }

    return phrases?.Phrases;
  }


  const handleAddToCart = () => {
    if (orderAmount === 0 || isNaN(orderAmount)) {
      const phrase = getPhrases();
      toast(phrase.AddToCart_NoProductMessage);
    }

    if (product.variant && product.unitOfMeasureCode && orderAmount) {
      const cartItem: CartItem[] = [{
        orderAmount,
        variantCode: product.variant.code,
        unitOfMeasureCode: product.unitOfMeasureCode,
        currentVariant: product.variant
      }];
      addToCart(dispatch)(cartItem);
    }
  };

  const enabled = data.enabled;

  return <ScReact {...props}>
    <div className="add-to-cart row" data-component="product-add-to-cart">
      <div className="d-flex w-100">
        <input
          data-bind-value="orderAmount"
          type="number"
          min="1"
          className="form-control amount mr-3"
          name="amount"
          autoComplete="off"
          value={orderAmount}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => setOrderAmount(parseInt(e.target.value, 10))}
          placeholder={data.amount}
          disabled={!enabled}
        />
        <button className="btn btn-cart btn-block" type="button" onClick={enabled ? handleAddToCart : undefined} disabled={!enabled}>
          <span className="fa cl-cart fa-lg mr-2"></span>
          <span className="btn-label" dangerouslySetInnerHTML={{ __html: data.addToCart }} />
        </button>
      </div>
    </div>
  </ScReact >;
};

export const ProductDetailAddToCart = (props: ScProps<AddToCartProps>) => (
  <Provider store={store}>
    <ProductDetailAddToCartComponent {...props} />
  </Provider>
);
