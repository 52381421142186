import React from 'react';
import { ScReact } from '../ScReact';
import { ScProps } from '../ScProps';
import styles from './BrandsList.module.scss';
import { Brand, BrandsListData } from './BrandsListData';

export const BrandsList = (props: ScProps<BrandsListData>) => {
  const { data: { title, link, brands }, isEdit } = props;
  const shouldDisplay = isEdit || link.text;

  return <ScReact {...props} className={styles.BrandsList}>
    <div className="mb-3">
      <h2 className="h2 mb-3" dangerouslySetInnerHTML={{ __html: title }} />
      {shouldDisplay && <span className={styles.titleLink}>
        <a href={link.href}>{link.text} <span className={`${styles.arrow} fa fa-long-arrow-right text-theme`}></span></a>
      </span>}
    </div>
    <div className={styles.brandsList}>
      {brands.map((brand: Brand) =>
        <div key={brand.link} className={styles.brand}>
          <a href={brand.link}>
            <img src={brand.image} className={styles.brandImage} />
          </a>
        </div>
      )}
    </div>
  </ScReact>;
};
