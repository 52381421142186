import * as React from 'react';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { mapProductData } from '@utils/mapProductsData';
import { context } from '@services/meta-context';
import { useUserPrices } from './useUserPrices';
import { ProductTile } from './ProductTile';
import { SwipableProductTileList } from './Components/SwipeableProductTileList';
import styles from './ProductTileList.module.scss';

import type { ProductData } from '@utils/mapProductsData';
import type { Product, Variant, UnitOfMeasure, Media as MediaData } from '@sctypes/product/Product';
import type { Prices } from '@sctypes/product/Prices';


export interface ProductTileListingProps {
  title?: string;
  hasGlider?: boolean;
  hideGliderNavigation?: boolean;
  slidesPerView?: number;
  products: Product[];
  phrases?: any;
  fallbackImage?: MediaData;
  isEdit?: boolean;
  showAddToCartButton: boolean;
}

interface VariantUomCodes {
  UnitOfMeasureCode: string;
  VariantCode: string;
}

export const ProductTileList = (props: ProductTileListingProps) => {
  const { fetchUserPrices } = useUserPrices();
  const productsState = useSelector((state: any) => state.productsListing);
  const [prices, setPrices]: [any, (prices: any) => void] = useState({});
  const { title, hasGlider, hideGliderNavigation, slidesPerView, phrases, fallbackImage } = props;

  const products: Product[] = productsState.entities?.results ?? props.products;

  useEffect(() => {
    if (context.user.isLoggedIn) {
      const variantProductMapping = new Map<string, string>();

      // Check if Prices has a key for [product.code]
      const productsWithoutPricing = products.filter(product => !Object.prototype.hasOwnProperty.call(prices, product.code));

      const unitsOfMeasureCodes: VariantUomCodes[] = productsWithoutPricing.reduce(
        (uomCode: VariantUomCodes[][], product: Product) => {
          const variantUomCodes: VariantUomCodes[][] = product.variants.map((variant: Variant) =>
            variant.unitsOfMeasure.map((uom: UnitOfMeasure) => {
              variantProductMapping.set(variant.code, product.code);

              return {
                VariantCode: variant.code,
                UnitOfMeasureCode: uom.code
              };
            })
          );

          return uomCode.concat(variantUomCodes);
        },
        []).flat();

      unitsOfMeasureCodes.length > 0 && fetchUserPrices(unitsOfMeasureCodes).then((priceData: any) => {
        const newPrices: any = { ...prices };
        priceData.data.map((prices: Prices) => {
          if (!variantProductMapping.get(prices.variantCode)) {
            console.log(`VariantCode not found in variantProductMapping: ${prices.variantCode}`);
            return;
          }

          const productCode = variantProductMapping.get(prices.variantCode);
          if (!newPrices[productCode]) {
            newPrices[productCode] = {};
          }

          const key = `${prices.variantCode}:${prices.unitOfMeasureCode}`;
          newPrices[productCode][key] = prices;
        });

        setPrices(newPrices);
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [products]);

  if (!products) {
    if (hasGlider && props.isEdit) {
      return <h2 className="h2" dangerouslySetInnerHTML={{ __html: title }} />;
    }

    return null;
  }

  if(hasGlider) {
    return (
      <SwipableProductTileList
        fallbackImage={fallbackImage}
        hideGliderNavigation={hideGliderNavigation}
        phrases={phrases}
        prices={prices}
        products={products}
        slidesPerView={slidesPerView}
        title={title}
        showAddToCartButton={props.showAddToCartButton}
      />
    );
  }

  return (
    <div className={`${styles.ProductTileList}`}>
      {products.map((product: Product, idx: number) => {
        const mappedProduct: ProductData = mapProductData(product);
        if (!mappedProduct?.variant) {
          return null;
        }

        return (
          <ProductTile
            product={mappedProduct}
            key={`${mappedProduct.code}-${idx}`}
            phrases={phrases}
            prices={prices[mappedProduct.code]}
            fallbackImage={fallbackImage}
            showAddToCartButton={props.showAddToCartButton}
          />
        );
      })}
    </div>
  );
};
