/* TODO: Replace with toast component once that is implemented */
import React from 'react';
import { useTranslation } from '@hooks/useTranslation';
import { Modal } from '@components-react/Modal/Modal';

export interface IErrorMessageModalProps {
  phrases: IPhrases;
  dismissModal: any;
}

export const ErrorMessageModal = (props: IErrorMessageModalProps) => {
  const { dismissModal, phrases} = props;
  const { t } = useTranslation(phrases);

  const modalButtons = [{
    type: 'primary',
    label: 'OK',
    onClick: dismissModal
  }];

  return (
    <Modal
      title={t('AddToOrderList_Error_Title')}
      buttons={modalButtons}
      onModalClose={dismissModal}
      hasFooterSeparator
    >
      <h3>{t('AddToOrderList_Error_Message')}</h3>
    </Modal>
  );
};
