import * as React from 'react';
import { Provider } from 'react-redux';
import store from '@state/store';
import ConnectedFilterBadges from '../Connectors/ConnectedFilterBadges';
import type { IFilterBadges } from '../Components/FilterBadges/FilterBadges';

export const ProvideFilterBadges = (props: IFilterBadges) => {
  return (
    <Provider store={store}>
      <ConnectedFilterBadges {...props} />
    </Provider>
  );
};
