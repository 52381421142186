import React from 'react';
import { Swiper, SwiperSlide, Autoplay, Lazy } from '@lib/swiper';
import { ScReact } from '../ScReact';
import { ScProps } from '../ScProps';
import styles from './CTACarousel.module.scss';
import { CTACarouselData, Slide } from './CTACarouselData';

export const CTACarousel = (props: ScProps<CTACarouselData>) => {
  const { slidesCount, slides } = props.data;

  return <ScReact {...props}>
    <div className={`${styles.CtaCarousel} swiper`}>
      <Swiper
        autoplay={{ delay: props.data.interval }}
        lazy={true}
        modules={[Autoplay, Lazy]}
        preloadImages={true}
        watchSlidesProgress={true}
        speed={props.data.animationDuration}>
        {slides && slides.map((slide: Slide, key: number) =>
          <SwiperSlide key={key}>
            <div className="row m-0 flex-column flex-lg-row">
              <div className="col-12 col-lg-6 d-flex flex-column mb-3 mb-lg-0 justify-content-center align-items-start">
                <div className="d-flex flex-row align-items-center">
                  <div className={`${styles.slideCount} mr-3 font-weight-bold text-theme`}
                    dangerouslySetInnerHTML={{ __html: `${slide.index}/${slidesCount}` }}></div>
                  <div className="d-flex align-items-center">
                    {renderBullet(slide, slidesCount)}
                  </div>
                </div>

                <div className={`${styles.title} mb-3`} dangerouslySetInnerHTML={{ __html: slide.title }}></div>
                <div className={`${styles.text} mb-3`} dangerouslySetInnerHTML={{ __html: slide.text }}></div>
                <a className="btn btn-primary btn-xl" href={slide.link.href} target={slide.link.target}>{slide.link.text}</a>
              </div>

              <div className={`${styles.imageContainer} col-12 col-lg-6`}>
                <img src={slide.image.src} alt={slide.image.alt} className="swiper-lazy" />
                <div className="swiper-lazy-preloader swiper-lazy-preloader-black"></div>
              </div>
            </div>
          </SwiperSlide>
        )}
      </Swiper>
    </div>
  </ScReact >;
};

function renderBullet(slide: Slide, count: number) {
  const rows = [];
  for (let i = 1; i <= count; i++) {
    let bulletClassNames = 'swiper-pagination-bullet mr-1';
    if (slide.index >= i) {
      bulletClassNames += ' swiper-pagination-bullet-active';

      if (slide.index === i) {
        bulletClassNames += ' last';
      }
    }
    rows.push(<span key={i} className={bulletClassNames}></span>);
  }
  return rows;
}
