import React from 'react';
import { UrlSet } from '@sctypes/product/UrlSet';

export interface ISortOrderItem {
  code?: string;
  value?: string;
  urlSet: UrlSet;
  selected?: boolean;
}

export interface ISortOrder {
  items: ISortOrderItem[];
  updateSortOrder: (arge0: ISortOrderItem) => void;
}


export const SortOrder = ({ items,updateSortOrder }: ISortOrder) => {
  const handleSortOrderChange = async (e: any) => {
    e.preventDefault();
    const option = e.target.options[e.target.selectedIndex];
    if (!option) {
      return;
    }

    const item: ISortOrderItem = {
      urlSet: {
        api: option.getAttribute('data-apiurl'),
        web: option.getAttribute('data-weburl')
      }
    };
    updateSortOrder(item);
  };

  const selectedCode = items.filter((item: ISortOrderItem) => item.selected)[0]?.code
    ?? items[0]?.code;

  return (
    <>
      <select
        className="custom-select custom-select-lg"
        defaultValue={selectedCode}
        onChange={handleSortOrderChange}>
        {items.map((item: ISortOrderItem) => {
          return (
            <option
              value={item.code}
              data-weburl={item.urlSet?.web}
              data-apiurl={item.urlSet?.api}
              // @ts-ignore
              rel={item.rel}
              key={item.code}>{item.value}</option>
          );
        })}
      </select>
    </>
  );
};
